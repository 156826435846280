import styled from 'styled-components/macro';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Settings = () => {

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h1>Réglages</h1>
  </S.Container>
};

export default Settings;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  h1 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`