import { createContext, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Router from "./components/Router/Router";
import { GlobalStyle } from './contexts/GlobalStyle';
import { darkTheme, globalTheme, lightTheme } from './contexts/Themes';

export const Context = createContext();

const App = () => {
  const [theme, setTheme] = useState(localStorage.getItem('saint_bernard_services_theme') || 'light');
  const [notif, setNotif] = useState({ profile: false });
  const [settings, setSettings] = useState({});
  const [allSettings, setAllSettings] = useState({});

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Context.Provider value={{ theme, setTheme, notif, setNotif, settings, setSettings, allSettings, setAllSettings }}>
    <ThemeProvider theme={{ ...globalTheme, ...theme === 'dark' ? darkTheme : lightTheme }}>
      <GlobalStyle />
      <Router />
    </ThemeProvider>
  </Context.Provider>
}

export default App;