import styled from 'styled-components/macro';
import { Radio, RadioContainer } from '../StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const WithVehicle = (props) => {
  const { setType, radio, setRadio } = props;

  const handleChange = (e) => {
    const val = e.target.value;
    setRadio(val)

    // si pas de véhicule : pas de chauffeur saint-bernard
    if (val == 2) setType(prev => {
      const arr = prev.filter(e => e !== 1);
      return arr;
    });
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <RadioContainer>
    <S.Radio htmlFor={1} checked={radio == 1}>
      <input type='radio' checked={radio == 1} value={1} id={1} name="with_vehicle" onChange={handleChange} />
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
      <span><strong>Avec</strong><br />mon véhicule</span>
    </S.Radio>
    {/* <S.Radio htmlFor={2} checked={radio == 2}>
      <input type='radio' checked={radio == 2} value={2} id={2} name="with_vehicle" onChange={handleChange} />
      <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6356 4.25H13.6V9.5H7.26063L8.97062 5.34922C9.24062 4.6875 9.89875 4.25 10.6356 4.25ZM16.3 9.5V4.25H20.8675C21.4131 4.25 21.9306 4.49063 22.2738 4.90625L26.0537 9.5H16.3ZM30.7112 9.55469L25.0863 2.71875C24.0625 1.47188 22.51 0.75 20.8675 0.75H10.6356C8.425 0.75 6.43937 2.05703 5.62375 4.04766L3.28375 9.74062C1.945 10.2547 1 11.518 1 13V19.125C1 20.093 1.80437 20.875 2.8 20.875H4.67313C5.10063 23.3578 7.3225 25.25 10 25.25C12.6775 25.25 14.8994 23.3578 15.3269 20.875H22.6731C23.1006 23.3578 25.3225 25.25 28 25.25C30.6775 25.25 32.8994 23.3578 33.3269 20.875H35.2C36.1956 20.875 37 20.093 37 19.125V16.5C37 12.9344 34.255 9.99219 30.7112 9.55469ZM30.5481 20.875C30.1769 21.8922 29.1756 22.625 28 22.625C26.8244 22.625 25.8231 21.8922 25.4519 20.875C25.3506 20.6016 25.3 20.3062 25.3 20C25.3 18.5508 26.5094 17.375 28 17.375C29.4906 17.375 30.7 18.5508 30.7 20C30.7 20.3062 30.6437 20.6016 30.5481 20.875ZM10 22.625C8.82437 22.625 7.82312 21.8922 7.45187 20.875C7.35062 20.6016 7.3 20.3062 7.3 20C7.3 18.5508 8.50937 17.375 10 17.375C11.4906 17.375 12.7 18.5508 12.7 20C12.7 20.3062 12.6437 20.6016 12.5481 20.875C12.1769 21.8922 11.1756 22.625 10 22.625Z" stroke="#C8C8C8" strokeDasharray="2 2" />
      </svg>
      <span><strong>Sans</strong><br />mon véhicule</span>
    </S.Radio> */}
  </RadioContainer>
};

export default WithVehicle;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Radio = styled(Radio)`
  svg {
    width: 40px;
    height: 40px;
  }

  &:last-of-type svg {
    /* fill: none; */
  }
`