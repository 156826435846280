import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import Api from '../../contexts/Api';
import Formfields from '../../components/form/Formfields';
import { getValue, prepareFormData } from '../../contexts/Utils';
import { Button } from '../../components/StyledComponents';
import VehiclesManagement from '../../components/form/VehiclesManagement';
import { useNotification } from '../../components/UseNotification';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditUser = () => {
  const { notification } = useNotification();
  const { uuid } = useParams();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  // 
  // ─── GET USER INFO ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsers = async () => {
      const params = {
        query: { user_get: uuid, getProfilePicture: true },
        method: 'GET',
        endpoint: '/users'
      }

      const query = await Api(params);
      if (query.data) setData(query.data);
    }

    getUsers();
  }, [uuid])

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = prepareFormData({ formId: 'form' })
    if (formData.errors) return setErrors(formData.errors)
    else setErrors({})

    const fields = formData.fields;

    // 
    // ─── SUBMIT PASSWORD ───────────────────────────────────────
    //
    const update_password = Boolean(fields.password_old.length && fields.password.length);
    if (update_password) {
      const passwordfields = { password: fields.password, password_old: fields.password_old }
      const passwordParams = {
        data: { fields: passwordfields, update_password: true, user_edit: data.user_id },
        endpoint: '/users',
        method: 'PUT'
      }
      const passwordUpdate = await Api(passwordParams);
      notification({ variant: passwordUpdate.success ? 'success' : 'error', message: passwordUpdate.message })
    }

    // 
    // ─── SUBMIT OTHER INFOS ───────────────────────────────────────
    //
    delete fields.password_old;
    delete fields.password;

    if (fields.email === data.email) delete fields.email;

    const infoParams = {
      data: { fields, user_edit: data.user_id },
      endpoint: '/users',
      method: 'PUT'
    }
    const userUpdate = await Api(infoParams);

    notification({ variant: userUpdate.success ? 'success' : 'error', message: userUpdate.message })
  }

  const isClient = data.role === 2
  const isDriver = data.role === 3
  const isParticular = data.driver_type === 1

  let formFields = [
    { component: "text", name: "username", label: "Nom d'utilisateur", required: true, disabled: true },
    { width: 'calc(50% - 5.5px)', component: "text", name: "firstname", label: 'Prénom', required: true },
    { width: 'calc(50% - 5.5px)', component: "text", name: "name", label: 'Nom', required: true },
    { type: "email", component: "text", name: "email", label: "Email", required: true },
    { type: "tel", component: "text", name: "phone", label: "Numéro de téléphone", required: true },
    { type: "password", component: "text", name: "password_old", label: 'Mot de passe actuel', required: false },
    { type: "password", component: "text", name: "password", label: 'Nouveau mot de passe', required: false },
    { cond: isDriver, component: "text", name: "driving_licence_id", label: "Numéro de permis", required: true },
    { cond: isDriver, component: "datepicker", name: "driving_licence_date", label: "Date d'obtention", required: true },
    { cond: isDriver, component: "text", name: "driving_licence_place", label: "Lieu d'obtention", required: true },
    { cond: isDriver, component: "text", name: "driving_licence_prefecture", label: "Préfecture ayant délivré le permis", required: true },
    { cond: isDriver && !isParticular, name: "driver_licence", label: "Licence", required: true },
    { cond: isDriver && !isParticular, name: "driver_siret", label: "Numéro de siret", required: true },
  ]

  let hourlyPriceField;
  if (isDriver) hourlyPriceField = { width: 'calc(50% - 5.5px)', type: "number", step: '0.01', label: "Prix/minute (€)", required: true, default: data.hourly_price, noStar: true, error: getValue(errors, ['hourly_price', 'message']), disabled: true }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h1>Modifier un utilisateur</h1>
    {data.profile_picture && <S.ProfilPictureContainer><S.ProfilePicture src={`data:image/jpeg;base64, ${data.profile_picture}`} alt="Profil" /></S.ProfilPictureContainer>}
    <S.Form onSubmit={handleSubmit} id="form">
      <h2>Informations</h2>
      {formFields.map((e) => ((e.cond || !e.hasOwnProperty('cond')) && <Formfields key={e.name} field={{ ...e, default: data[e.name], noStar: true, error: getValue(errors, [e.name, 'message']) }} />))}
      {isDriver && <>
        <h2 className='price-title'>Prix par minute</h2>
        <Formfields field={hourlyPriceField} />
      </>}
      <S.Button as="input" type="submit" value="Enregistrer" $isDriver={isDriver} />
    </S.Form>
    {isClient && <S.Vehicles>
      <h2>Véhicules</h2>
      <VehiclesManagement data={data} setData={setData} errors={errors} setErrors={setErrors} />
    </S.Vehicles>}
  </S.Container>
};

export default EditUser;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  h1 {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 22px;
    width: 100%;

    &.price-title {
      margin-top: 20px;
    }
  }
`

S.ProfilPictureContainer = styled.div`
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
  border-radius: 50%;
  overflow:hidden;
`

S.ProfilePicture = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

S.Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  gap: 11px;
`

S.Button = styled(Button)`
  ${({ $isDriver }) => !$isDriver && `height: fit-content;`}
  ${({ $isDriver }) => $isDriver && `margin-top: 20px;`}
`

S.Vehicles = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  h2 {
    margin-bottom: 10px;
  }
`