import styled from 'styled-components/macro';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Popup = ({ onBgClick, children }) => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content>
      {children}
    </S.Content>
    <S.Background onClick={onBgClick}></S.Background>
  </S.Container>
};

export default Popup;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  height: 100svh;

  p {
    font-size: 24px;
    font-family: ${({ theme }) => theme.titleFont};
    color: ${({ theme }) => theme.primary};
    font-weight: bold;
  }
`

S.Content = styled.div`
  background: ${({ theme }) => theme.card};
  padding: 30px;
  border-radius: 5px;
  gap: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  max-width: 350px;
  text-align: center;
`

S.Background = styled.div`
  inset: 0;
  z-index: 999;
  position: fixed;
  background: #000000a3;
`