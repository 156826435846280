import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import Api from '../../contexts/Api';
import { Button, Icon } from '../StyledComponents';
import { useNotification } from '../UseNotification';
import { Context } from '../../App';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Team = (props) => {
  const { notification } = useNotification();
  const cookies = new Cookies();
  const { setNotif } = useContext(Context);
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const { data, setData } = props;
  const userTeam = data.team || {};
  const [drivers, setDrivers] = useState({});
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState('');
  const [focusActive, setFocusActive] = useState(false);
  const valueHasChanged = useRef(true);

  useEffect(() => {
    // 
    // ─── HANDLE SEARCH CHANGE ───────────────────────────────────────
    //
    const handleSearchChange = async () => {
      // do not run the function if we clicked on a result
      if (!valueHasChanged.current) return valueHasChanged.current = true;

      if (selected.length) setSelected('');
      if (!search) return setDrivers({});

      const fields = ['firstname', 'name', 'email']

      const params = {
        query: { search, driver_type: 1, role: 3, status: 2, notUserId: user.user_id, fields },
        endpoint: '/users',
        method: 'GET'
      }
      const driversQuery = await Api(params);
      if (driversQuery.success) return setDrivers(driversQuery.data)
      setDrivers({});
    }

    const delay = setTimeout(() => {
      handleSearchChange();
    }, 200)

    return () => clearTimeout(delay)
  }, [search, selected, user.user_id])

  const handleChange = (e) => setSearch(e.target.value);

  const notInTeam = (e) => {
    const team = data.team || {}
    const email = e.email;
    const length = Object.values(team).filter(team => team.driver === email || team.invited_driver === email).length;

    // if length : is in team
    return !Boolean(length);
  }

  // 
  // ─── HANDLE RESULT CLICK ───────────────────────────────────────
  //
  const handleResultClick = (e) => {
    setSearch(`${e.firstname} ${e.name}`);
    setSelected(e.email);
    valueHasChanged.current = false;
  }

  // 
  // ─── HANDLE SEARCH FOCUS ───────────────────────────────────────
  //
  const handleFocus = (val) => {
    if (!val) {
      setTimeout(() => {
        setFocusActive(val);
      }, 150);
      return
    }

    setFocusActive(val);
  }

  // 
  // ─── SEND PROPOSAL ───────────────────────────────────────
  //
  const sendProposal = async () => {
    const invited_driver_email = drivers[selected].email;

    const params = {
      data: { fields: { driver_id: user.user_id, invited_driver_id: invited_driver_email } },
      endpoint: '/teams',
      method: 'POST'
    }
    const query = await Api(params);

    const message = query.success ? 'La demande a été envoyée' : "La demande n'a pas pu être envoyée";
    if (query.success) notification({ variant: query.success ? 'success' : 'error', message });

    // update team in state
    if (query.success) {
      setSearch('');
      setData(prev => {
        const team = { ...prev.team }
        team[query.team_id] = { team_id: query.team_id, driver: user.email, invited_driver: invited_driver_email, accepted: 0 };
        return { ...prev, team };
      })
    }
  }

  // 
  // ─── REMOVE DRIVER FROM TEAM / CANCEL PROPOSAL ───────────────────────────────────────
  //
  const removeDriver = async (team_id, isACancel = true, isInvited = false) => {
    // isACancel : cancel of proposal
    const params = {
      data: { team_id },
      endpoint: '/teams',
      method: 'DELETE',
    }
    const query = await Api(params);

    let message;
    if (isACancel) message = query.success ? 'La demande a été annulée' : "La demande n'a pas pu être annulée";
    else message = query.success ? "Le chauffeur a été supprimé de l'équipe" : "Le chauffeur n'a pas pu être supprimé";
    if (isInvited) message = query.success ? 'La demande a été refusée' : "La demande n'a pas pu être refusée";
    notification({ variant: query.success ? 'success' : 'error', message });

    // remove from state
    if (query.success) {
      if (isACancel && Object.entries(userTeam).filter(([, value]) => !value.accepted && user.email === value.invited_driver).length === 1) {
        setNotif(prev => ({ ...prev, profile: false }));
      }

      setData(prev => {
        const team = { ...prev.team }
        delete team[team_id];
        return { ...prev, team };
      })
    }
  }

  // 
  // ─── ACCEPT DRIVER PROPOSAL ───────────────────────────────────────
  //
  const acceptDriver = async (team_id) => {
    const params = {
      data: { team_id, fields: { accepted: 1 } },
      endpoint: '/teams',
      method: 'PUT',
    }
    const query = await Api(params);

    const message = query.success ? 'Demande acceptée' : query.message;
    notification({ variant: query.success ? 'success' : 'error', message });

    // update state
    if (query.success) {
      if (Object.entries(userTeam).filter(([, value]) => !value.accepted && user.email === value.invited_driver).length === 1) {
        setNotif(prev => ({ ...prev, profile: false }));
      }

      setData(prev => {
        const team = { ...prev.team }
        team[team_id].accepted = 1;
        return { ...prev, team };
      })
    }
  }

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Search>
      <S.TextField>
        <div>
          <input
            value={search}
            onChange={handleChange}
            type="text"
            name="team"
            id="team"
            autoComplete="off"
            onFocus={() => handleFocus(true)}
            onBlur={() => handleFocus(false)}
          />
          <label htmlFor="team">Ajouter un coéquipier</label>
        </div>
      </S.TextField>
      {focusActive && <S.Results>
        {Object.values(drivers)
          .filter(notInTeam)
          .map(e => <div key={e.email}><S.Result onClick={() => handleResultClick(e)}>
            {e.firstname} {e.name}
          </S.Result></div>)}
      </S.Results>}
    </S.Search>
    {Boolean(selected.length) && <S.Button onClick={sendProposal}>Envoyer une demande</S.Button>}
    {Boolean(userTeam && Object.keys(userTeam).length) && <div>
      <S.DriverContainer>
        {Object.entries(userTeam).filter(([, value]) => !value.accepted && user.email === value.invited_driver).map(([key, value], i) => {
          return <Fragment key={key}>
            {i === 0 && <p>Ils m'ont invité</p>}
            <S.Driver>
              <span className='name'>{value.driver}</span>
              <div>
                <Icon onClick={() => acceptDriver(key)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                  <span>Accepter</span>
                </Icon>
                <Icon onClick={() => removeDriver(key, true, true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
                  <span>Refuser</span>
                </Icon>
              </div>
            </S.Driver>
          </Fragment>
        })}
      </S.DriverContainer>
      <S.DriverContainer>
        {Object.entries(userTeam).filter(([, value]) => !value.accepted && user.email === value.driver).map(([key, value], i) => {
          return <Fragment key={key}>
            {i === 0 && <p>Mes demandes en attentes</p>}
            <S.Driver>
              <span className='name'>{value.invited_driver}</span>
              <div>
                <Icon onClick={() => removeDriver(key, true)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
                  <span>Annuler</span>
                </Icon>
              </div>
            </S.Driver>
          </Fragment>
        })}
      </S.DriverContainer>
      <S.DriverContainer>
        {Object.entries(userTeam).filter(([, value]) => value.accepted).map(([key, value], i) => {
          const isDriver = user.email === value.driver;
          return <Fragment key={key}>
            {i === 0 && <p>Membres de mon équipe</p>}
            <S.Driver>
              <span className='name'>{isDriver ? value.invited_driver : value.driver}</span>
              <div>
                <Icon onClick={() => removeDriver(key, false)}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>
                  <span>Supprimer</span>
                </Icon>
              </div>
            </S.Driver>
          </Fragment>
        })}
      </S.DriverContainer>
    </div>}
  </S.Container>
};

export default Team;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`

S.Button = styled(Button)`
  width: fit-content;
  margin-left: auto;
`

S.DriverContainer = styled.div`
  p {
    margin: 20px 0 10px 0;
  }
`

S.Driver = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: ${({ theme }) => `1px solid ${theme.navbarIcons}`};

  &:first-of-type {
    padding-top: 0;
  }
  &:last-of-type {
    border-bottom: unset;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .name {
    background: ${({ theme }) => theme.navbarIcons};
    padding: 5px 12px;
    color: ${({ theme }) => theme.textInverse};
    border-radius: 10px;
    font-size: 14px;
  }

  // right
  div {
    display: flex;
    align-items: center;
    gap: 10px;

    // contenant svg
    div {
      border-radius: 50%;
      background: ${({ theme }) => theme.primary};

      svg {
        fill: ${({ theme }) => theme.textInverse};
        width: 17px;
        padding: 8px 8.5px;
      }

      span {
        top: 37px;
      }
    }
  }
`

S.TextField = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  & > div {
    position: relative;
    display: flex;
    align-items: center;

    & label {
      cursor: text;
      position: absolute;
      left: 10px;
      left: ${({ icon }) => icon ? '38px' : '10px'};
      font-size: 14px;
      transition: .3s;
      color: ${({ theme }) => theme.text};
    }
    & input {
      padding: 17px 10px;
      padding-bottom: 7px;
      padding-right: ${({ password }) => Boolean(password) ? '40px' : '10px'};
      padding-left: ${({ icon }) => icon ? '38px' : '17px'};
      font-size: 15px;
      border: none;
      width: 100%;
      box-sizing: border-box;
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.inputBg};
      font-family: ${({ theme }) => theme.primaryFont};
      
      &:focus, :not(&[value=""]) {
        outline: none;
        
        & + label {
          transform: translateY(-20px);
          font-size: 10px;
          background: ${({ theme }) => theme.primary};
          color: ${({ theme }) => theme.textInverse};
          border-radius: 5px;
          padding: 1px 5px;
        }
      }
    }
  }
`

S.Search = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  position: relative;
`

S.Results = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  background: ${({ theme }) => theme.text};
  width: 100%;
  z-index: 100;
  background: ${({ theme }) => theme.inputBg};
  max-height: 230px;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
  }

  @media(min-width: 800px) {
    background: #fff;
    color: #000;
  }
`

S.Result = styled.div`
  padding: 5px 10px;
  transition: .3s;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;

  &:hover {
    background: #c6c7d2;
  }
  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 10px;
  }
`