import styled from 'styled-components/macro';
import { Button } from './StyledComponents';
import Api from '../contexts/Api';
import { useNotification } from './UseNotification';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { formatDate, formatTime, isInReunion } from '../contexts/Utils';
import Popup from './Popup';
import noMissions from '../assets/driver-sleep.png';
import { useState } from 'react';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalMissionDelete = (props) => {
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const { modal, setModal } = props;
  const { notification } = useNotification();
  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const closeModal = () => {
    setModal({
      show: false
    })
  }

  const handleValidate = async () => {
    const mission = modal.mission;
    const data = { mission_id: modal.mission_id };

    if (mission.status === 2) {
      data.send_mail = 'order_canceled_mission';
      data.send_mail_data = {
        missionTitle: `${mission.origin_city} - ${mission.destination_city}`,
        date: mission.date_type === 1 ? 'Dès que possible' : formatDate({ display: 'date', time: mission.date }) + ' à ' + formatDate({ display: 'timer', time: isInReunion(mission.origin_cp) ? new Date(mission.date).setHours(new Date(mission.date).getHours() - 2) : mission.date }),
        origin: `${mission.origin_address} ${mission.origin_cp} ${mission.origin_city}`,
        additional: mission.additional_address ? `${mission.additional_address} ${mission.additional_cp} ${mission.additional_city}` : 'Aucune',
        destination: `${mission.destination_address} ${mission.destination_cp} ${mission.destination_city}`,
        duration: formatTime({ duration: mission.duration, display: 'hour' }),
      }
    }

    const params = {
      data,
      endpoint: '/missions',
      method: 'DELETE'
    }

    const query = await Api(params);

    if (query.success) {
      if (user.active_role === 1) {
        notification({ variant: 'success', message: query.message });
        navigate('/');
      }
      // popup
      else setShowPopup(true);
    }
    else notification({ variant: 'error', message: query.message });
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  if (showPopup) return <S.Container>
    <Popup onBgClick={() => navigate('/trajets')}>
      <p>Vous avez supprimé la mission</p>
      <img width={160} src={noMissions} alt="Mission supprimée" />
      <Button onClick={() => navigate('/nouveau-trajet')}>Créer une nouvelle mission</Button>
    </Popup>
  </S.Container>
  return <S.Container>
    <S.Content>
      <p>Supprimer la mission ?</p>
      <S.Buttons>
        <Button onClick={closeModal} outline={true}>Annuler</Button>
        <Button onClick={handleValidate}>Confirmer</Button>
      </S.Buttons>
    </S.Content>
    <S.Background onClick={closeModal}></S.Background>
  </S.Container>
};

export default ModalMissionDelete;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
`

S.Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 23%);
  padding: 20px;
  max-width: 400px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  z-index: 1000;
  text-align: center;
`

S.Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 160px;
  }
`

S.Background = styled.div`
  inset: 0;
  z-index: 999;
  position: fixed;
  background: #000000a3;
`