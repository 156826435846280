import styled from 'styled-components/macro';
import { Button } from './StyledComponents';
import { useNotification } from './UseNotification';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const CurrentLocation = (props) => {
  const { setData } = props;
  const { notification } = useNotification();

  const handleClick = () => {
    navigator.geolocation.getCurrentPosition(success, error, { timeout: 5000 });
  }

  const success = async (position) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;

    const query = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json&zoom=18&addressdetails=1`);
    const data = await query.json();

    const { house_number = '', road = '', postcode = '' } = data.address;
    const city = data.address.village || data.address.city || '';

    const name = props.name;
    const geo = `${house_number} ${road} ${postcode} ${city}`;
    const address = `${house_number} ${road}`;
    setData(prev => ({ ...prev, [name]: geo, [`${name}_address`]: address, [`${name}_city`]: city, [`${name}_cp`]: postcode }));
  }

  const error = () => notification({ variant: 'error', message: "La localisation n'a pas pu être récupérée" });

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Button type='button' onClick={handleClick}>
      Ma position
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0c17.7 0 32 14.3 32 32V66.7C368.4 80.1 431.9 143.6 445.3 224H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H445.3C431.9 368.4 368.4 431.9 288 445.3V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V445.3C143.6 431.9 80.1 368.4 66.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H66.7C80.1 143.6 143.6 80.1 224 66.7V32c0-17.7 14.3-32 32-32zM128 256a128 128 0 1 0 256 0 128 128 0 1 0 -256 0zm128-80a80 80 0 1 1 0 160 80 80 0 1 1 0-160z" /></svg>
    </S.Button>
  </S.Container>
};

export default CurrentLocation;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  width: 100%;
  margin-top: 15px;
`

S.Button = styled(Button)`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;

  svg {
    fill: ${({ theme }) => theme.textInverse};
    height: 15px;
  }
`