import styled, { css } from 'styled-components/macro';
import { Button } from './StyledComponents';
import Api from '../contexts/Api';
import Formfields from './form/Formfields';
import { prepareFormData } from '../contexts/Utils';
import { useState } from 'react';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalExcel = (props) => {
  const { onClose } = props;
  const [datesEnabled, setDatesEnabled] = useState(true);

  const toggleDates = () => setDatesEnabled(!datesEnabled);

  const downloadExcel = async (e) => {
    e.preventDefault();
    let apiQuery = {};

    const data = prepareFormData({ formId: 'excel' });
    // get missions by date
    if (!data.fields.all) {
      apiQuery = {
        start_date: data.fields.start_date,
        end_date: data.fields.end_date
      }
    }

    const query = await Api({
      endpoint: '/excel',
      method: 'GET',
      query: apiQuery
    })

    const filename = data.fields.all ? 'export.xlsx' : `export_${data.fields.start_date}_${data.fields.end_date}.xlsx`;

    const link = document.createElement('a');
    link.href = "data:image/png;base64," + query.data;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();

    onClose();
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content id="excel">
      <p>Exporter les missions</p>
      <Formfields field={{ component: 'checkbox', name: 'all', label: 'Tout récupérer', onChange: toggleDates }} />
      <S.Fields $enabled={datesEnabled}>
        <Formfields field={{ component: 'datepicker', label: 'Date de début', name: 'start_date' }} />
        <Formfields field={{ component: 'datepicker', label: 'Date de fin', name: 'end_date' }} />
      </S.Fields>
      <S.Button onClick={downloadExcel}>Exporter</S.Button>
    </S.Content>
    <S.Background onClick={onClose}></S.Background>
  </S.Container>
};

export default ModalExcel;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
`

S.Content = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 23%);
  padding: 20px;
  max-width: 430px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  z-index: 1000;

  p {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  input[type="checkbox"] {
    background: ${({ theme }) => theme.background} !important;
    border-color: ${({ theme }) => theme.background} !important;
  }
`

S.Fields = styled.div`
  display: flex;
  gap: 50px;
  max-width: 350px;
  margin-top: 20px;
  background: ${({ theme }) => theme.background};
  padding: 20px;
  padding-bottom: 5px;
  border-radius: 5px;
  margin-bottom: 30px;

  input {
    background: ${({ theme }) => theme.background} !important;
    padding-left: 0 !important;
  }

  label {
    left: 0 !important;
  }

  ${({ $enabled }) => !$enabled && css`
    opacity: 0.5;
    pointer-events: none;
  `}
`

S.Button = styled(Button)`
  width: fit-content;
  height: fit-content;
`

S.Background = styled.div`
  inset: 0;
  z-index: 999;
  position: fixed;
  background: #000000a3;
`