import { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import Api from '../contexts/Api';
import { getValue, prepareFormData } from '../contexts/Utils';
import { Button } from '../components/StyledComponents';
import Formfields from '../components/form/Formfields';
import { useNotification } from '../components/UseNotification';
import { Context } from '../App';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AdminSettings = () => {
  const { notification } = useNotification();
  const [errors, setErrors] = useState({});
  const { settings } = useContext(Context);

  // 
  // ─── GEN RECOVERY TIME SELECT DATA ───────────────────────────────────────
  //
  // de 30min jusqu'à 4h
  const times = ['00', '15', '30', '45'];
  const recoveryTimeData = [];
  Array.from(Array(4).keys()).forEach(i => {
    return times.forEach(time => {
      const item = `${i.toString().padStart(2, '0')}:${time}`;
      recoveryTimeData.push({ id: item, label: item });
    })
  });
  recoveryTimeData.shift();
  recoveryTimeData.push({ id: '04:00', label: '04:00' });

  // 
  // ─── UPDATE A SETTING ───────────────────────────────────────
  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = prepareFormData({ formId: 'form' });
    if (formData.errors) return setErrors(formData.errors)
    else setErrors({});

    const params = {
      data: { fields: formData.fields, setting_id: settings.setting_id },
      endpoint: '/settings',
      method: 'PUT'
    }
    const query = await Api(params);

    const message = query.success ? 'Modifications effectuée' : 'Erreur lors de la modification';
    notification({ variant: query.success ? 'success' : 'error', message });
  }

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  const formFields = [
    { name: 'default_hourly_price', label: 'Prix horaire par défaut' },
    { name: 'min_hourly_price', label: 'Prix horaire minimum' },
    { name: 'max_hourly_price', label: 'Prix horaire maximum' },
    { name: 'waiting_minute_price', label: "Prix par minute d'attente" },
    { name: 'sbs_margin', label: "Marge Saint-Bernard (%)" },
    // { name: 'time_before_mission_display', label: "Temps avant l'apparition des missions", component: 'selectWithLabel', data: recoveryTimeData },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <h1>Réglages</h1>
    <S.Form onSubmit={handleSubmit} id="form">
      <div>
        {formFields.map((field) => <Formfields key={field.name} field={{ ...field, type: 'number', required: true, width: 'calc(50% - 5px)', default: settings[field.name], error: getValue(errors, [field.name, 'message']) }} />)}
      </div>
      <S.Button>Enregistrer</S.Button>
    </S.Form>
  </S.Container>
};

export default AdminSettings;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  
  h1 {
    margin-top: 20px;
    margin-bottom: 30px;
  }
`

S.Button = styled(Button)`
  display: flex;
  width: 170px;
  height: fit-content;
  justify-content: center;
  margin-left: auto;
`

S.Form = styled.form`
  > div { 
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 15px;
  }
`