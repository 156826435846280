import { useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components/macro';
import Cookies from 'universal-cookie';
import { formatDate, formatTime, getPrice, isInReunion } from '../contexts/Utils';
import Api from '../contexts/Api';
import { Button } from './StyledComponents';
import { useNotification } from './UseNotification';
import { Context } from '../App';
import Formfields from '../components/form/Formfields';
import Loading from './generic/Loading';
import ModalPostulation from './ModalPostulation';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TravelCard = (props) => {
  const { theme, settings, allSettings } = useContext(Context);
  const { notification } = useNotification();
  const { mission, team, proposals = [] } = props;
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const isClient = user.active_role === 2;
  const [selected, setSelected] = useState({ team: -1, driver: -1 });
  const [chooseTeam, setChooseTeam] = useState(user.driver_type === 1 ? true : false);
  const [price, setPrice] = useState('');
  const [fee, setFee] = useState('');
  const [modalPrice, setModalPrice] = useState({
    show: false,
  });
  const loading = useRef(true);

  const withVehicle = Boolean(mission.vehicle) && Object.keys(mission.vehicle).length > 0;
  const teamWithoutCreator = Object.values(team).filter(member => member.label !== mission.email);

  // 
  // ─── POSTULATIONS CHECK ───────────────────────────────────────
  //
  let hasInvitations = false, hasTeamPostulations = false;
  if (mission.status === 1 && user.active_role === 3) {
    const hasTeamInvite = (proposal) => proposal.invited_driver === user.email && proposal.teammate_approval === 0;
    const hasTeammatePostulations = (proposal) => proposal.driver === user.email && proposal.invited_driver !== null && proposal.driver_approval === 0;
    hasInvitations = proposals.some(proposal => hasTeamInvite(proposal) || hasTeammatePostulations(proposal));
  }
  if (mission.status === 1 && user.active_role === 2 && proposals.length > 0) hasTeamPostulations = true;

  // 
  // ─── SELECT DATA (CHOOSE DRIVER) GENERATION ───────────────────────────────────────
  //
  let drivers = [];
  const chooseDriverData = proposals.reduce((final, current) => {
    if (!drivers.includes(current.id) && !current.solo && current.driver !== user.email) {
      final.push({ id: current.id, label: `${current.label} / ${current.price}€` });
      drivers.push(current.id);
    }
    return final;
  }, []);

  useEffect(() => {
    const genPrice = async () => {
      if (mission.status === 1) {
        const obj = {
          duration: mission.duration,
          recovery: mission.recovery,
          recovery_waiting_time: mission.recovery_waiting_time,
          settings,
          allSettings,
          postal_code: mission.origin_cp,
          driver_hourly_price: user.hourly_price,
          separate: !isClient,
          // stripe_subscription_enabled: user.stripe_subscription_enabled
        }

        const price = await getPrice(obj);
        loading.current = false;
        setPrice(isClient ? price : price.amount);
        if (!isClient) setFee(price.fee);
      }
      else {
        loading.current = false;
        const sum = user.role === 2 ? mission.price + mission.price_fee : mission.price;
        setPrice(`${sum.toFixed(2)}€`);
      }
    }

    genPrice();
  }, [mission, settings, allSettings, user, isClient])

  // 
  // ─── HANDLE POSTULATE TO A MISSION ───────────────────────────────────────
  //
  const handlePostulate = async (opt) => {
    const { withTeammate, priceParam, date, solo = 0, external_payment = false } = opt;
    let data, priceForMail;

    if (withTeammate) {
      // de mon équipe
      if (chooseTeam) {
        data = { fields: { mission_id: mission.mission_id, driver_id: user.user_id, invited_driver_id: team[selected.team].label, driver_approval: 1 }, invitedDriverEmail: true }
      }
      // s'associer à un chauffeur
      else {
        data = { fields: { mission_id: mission.mission_id, driver_id: selected.driver, invited_driver_id: user.user_id, teammate_approval: 1 }, driverEmail: true };
      }
    }
    // postuler sans coéquipier
    else {
      data = { fields: { mission_id: mission.mission_id, driver_id: user.user_id } }
      if (!withVehicle) data = { fields: { ...data.fields, driver_approval: 1, teammate_approval: 1 } }
    }

    if (priceParam) {
      priceForMail = priceParam.amount;
      data.fields.price = priceParam.amount;
      data.fields.price_fee = priceParam.fee;
      // data.fields.price_fee = user.stripe_subscription_enabled ? 0 : priceParam.fee;
    }
    else if (!withTeammate || (withTeammate && chooseTeam)) {
      priceForMail = price;
      data.fields.price = price;
      data.fields.price_fee = fee;
      // data.fields.price_fee = user.stripe_subscription_enabled ? 0 : fee;
    }

    if (date) data.fields.date = date;
    data.fields.external_payment = external_payment;
    data.fields.solo = solo;

    // SEND MAIL IF PROPOSAL IS VALID FOR CLIENT
    const origin = `${mission.origin_address} ${mission.origin_cp} ${mission.origin_city}`;
    const destination = `${mission.destination_address} ${mission.destination_cp} ${mission.destination_city}`;
    const additional = mission.additional_city ? `${mission.additional_address} ${mission.additional_cp} ${mission.additional_city}` : 'Aucune';
    const mailDate = (date, date_type) => date_type === 1 ? 'Dès que possible' : formatDate({ display: 'date', time: date }) + ' à ' + formatDate({ display: 'timer', time: date });

    const duration = formatTime({ duration: mission.duration, display: 'hour' });

    const proposalIsValid = solo === 1 || !withVehicle;
    if (proposalIsValid) {
      data.send_mail_data = {
        mission_id: mission.mission_id,
        driver_id: user.user_id,
        duration,
        date: mailDate(new Date(mission.date).setHours(new Date(mission.date).getHours() - (isInReunion(mission.origin_cp) ? 2 : typeof mission.date == 'number' ? 0 : 2)), mission.date_type),
        origin,
        destination,
        additional
      }
      data.send_mail = 'new_order_answer';
    }
    // SEND MAIL TO INFORM TEAMMATE
    else if (withTeammate) {
      // price when choosed driver
      const currentDate = date || mission.date;
      if (!chooseTeam && !priceForMail) priceForMail = proposals.find(proposal => proposal.id === selected.driver)?.price;
      data.send_mail_data = {
        mission_id: mission.mission_id,
        driverFirstname: user.firstname,
        partnerEmail: chooseTeam ? team[selected.team].label : selected.driver,
        duration,
        date: chooseTeam ? mailDate(new Date(currentDate).setHours(new Date(currentDate).getHours() - 0), 2) + " - " : '',
        origin,
        destination,
        additional,
        missionTitle: `${mission.origin_city} - ${mission.destination_city}`,
        priceDivided: (priceForMail / 2).toFixed(2),
      }
      data.send_mail = 'new_mission_waiting_partner';
    }

    const params = {
      endpoint: '/proposals',
      method: 'POST',
      data
    }

    const query = await Api(params);

    const message = query.success ? 'Vous avez postulé à la mission' : query.message;
    notification({ variant: query.success ? 'success' : 'error', message });

    // remove mission from state
    // if (query.success) {
    //   setMissions(missions => {
    //     const newMissions = { ...missions };
    //     delete newMissions[mission.status][mission.mission_id];
    //     return newMissions;
    //   });
    // }
  }

  // 
  // ─── HANDLE RADIO CHANGE ───────────────────────────────────────
  //
  const handleRadioChange = () => {
    setSelected({ team: -1, driver: -1 });
    setChooseTeam(!chooseTeam);
  }

  // 
  // ─── SHOW MODAL PRICE ───────────────────────────────────────
  //
  const showModalPrice = async (withTeammate, solo = 0) => {
    let sbs_margin;

    if (user.driver_type !== 1) {
      // get sbs_margin to calculate fee if custom
      const twoDigit = mission.origin_cp.substring(0, 2);
      if (allSettings.hasOwnProperty(twoDigit)) sbs_margin = Number(allSettings[twoDigit].sbs_margin);
      else sbs_margin = Number(allSettings['default'].sbs_margin);
    }

    // gen date for input
    let date, hoursToAdd = isInReunion(mission.origin_cp) && mission.date_type == 1 ? 4 : 2;
    if (mission.date_type === 1) date = new Date(new Date().setHours(new Date().getHours() + hoursToAdd));
    else date = new Date(new Date(mission.date).setHours(new Date(mission.date).getHours() + hoursToAdd));

    date = new Date(date).toISOString().slice(0, 16);

    setModalPrice({
      show: true,
      withTeammate,
      price: user.driver_type === 1 ? undefined : { amount: price, fee },
      date,
      sbs_margin,
      solo
    });
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container as={props.canPostulate ? 'div' : Link} to={`/trajets/${mission.mission_id}`}>
    {mission.urgency === 1 && props.canPostulate && <S.Label>Urgent</S.Label>}
    <h3 className='cities'>{mission.origin_city} <span>→</span> {mission.destination_city}</h3>
    <S.TimeData>
      {mission.date_type === 1 ? <span>Dès que possible</span> : <span>{formatDate({ display: 'date', time: mission.date })} à {formatDate({ display: 'timer', time: new Date(new Date(mission.date).setHours(new Date(mission.date).getHours() - (isInReunion(mission.origin_cp) ? typeof mission.date == 'number' ? 2 : 4 : typeof mission.date == 'number' ? 0 : 2))) })}</span>}
      <span>{mission.distance}km - {formatTime({ duration: mission.duration, display: 'hour' })}</span>
    </S.TimeData>
    <S.Options>
      <span>
        <svg className='car' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
        {Boolean(mission.recovery) ? <svg className='loop' width="188" height="227" viewBox="0 0 188 227" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M71.3225 221.679C78.6663 228.774 90.5925 228.774 97.9363 221.679C105.28 214.585 105.28 203.064 97.9363 195.97L83.0138 181.611H94C145.935 181.611 188 140.976 188 90.8057C188 40.6355 145.935 0 94 0H18.8C8.40124 0 0 8.11576 0 18.1611C0 28.2065 8.40124 36.3223 18.8 36.3223H94C125.138 36.3223 150.4 60.7263 150.4 90.8057C150.4 120.885 125.138 145.289 94 145.289H83.0138L97.9363 130.874C105.28 123.779 105.28 112.259 97.9363 105.164C90.5925 98.0701 78.6663 98.0701 71.3225 105.164L24.3225 150.567C16.9787 157.661 16.9787 169.182 24.3225 176.277L71.3225 221.679Z" /></svg>
          : <svg className='car-two' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
        }
        {Boolean(mission.recovery) ? 'Aller-retour' : 'Aller simple'}
      </span>
      {withVehicle ? <span>
        <svg className='car-two' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg>
        Avec véhicule
      </span>
        : <span>Sans véhicule</span>}
    </S.Options>
    {!(mission.status === 1 && !isClient && !props.canPostulate) && <S.Price>
      <span>{mission.status === 1 ? 'Estimation' : mission.external_payment === 1 ? 'Estimation (paiement sur place)' : 'Prix'} : <Loading loading={loading.current}>{price}{!isClient && mission.status === 1 && `€ ${withVehicle ? '(Pour le binôme)' : ''}`}</Loading></span>
    </S.Price>}
    {(!props.canPostulate && hasInvitations || hasTeamPostulations) && <S.Info theme={theme}>Vous avez des demandes en attente</S.Info>}
    {props.canPostulate && <S.Postulation>
      {withVehicle ? <>
        <p>Postuler avec un coéquipier</p>
        {Object.keys(proposals).length > 0 && user.driver_type === 1 ? <Formfields field={{ component: 'radio', name: mission.mission_id, value: [{ value: 1, label: 'De mon équipe' }, { value: 2, label: "S'associer à un chauffeur" }], onChange: handleRadioChange, default: 1, required: true }} classNameOuter="radio" />
          : <p style={{ fontSize: '13px', marginTop: '-5px' }}>{user.driver_type === 1 ? 'De mon équipe' : "S'associer à un chauffeur"}</p>}
        {chooseTeam ? <S.Postuler>
          <Formfields field={{ component: 'select', onChange: (e) => setSelected({ ...selected, team: e }), label: "Choisir un coéquipier", data: teamWithoutCreator, required: true }} />
          <S.IconButton valid={selected.team != -1} onClick={() => selected.team != -1 && showModalPrice(true)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" /></svg>
          </S.IconButton>
        </S.Postuler>
          :
          <S.Postuler>
            <Formfields field={{ component: 'select', onChange: (e) => setSelected({ ...selected, driver: e }), label: "Choisir un chauffeur", data: chooseDriverData, required: true }} />
            <S.IconButton valid={selected.driver != -1} onClick={() => selected.driver != -1 && handlePostulate({ withTeammate: true })}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M243.8 339.8C232.9 350.7 215.1 350.7 204.2 339.8L140.2 275.8C129.3 264.9 129.3 247.1 140.2 236.2C151.1 225.3 168.9 225.3 179.8 236.2L224 280.4L332.2 172.2C343.1 161.3 360.9 161.3 371.8 172.2C382.7 183.1 382.7 200.9 371.8 211.8L243.8 339.8zM512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 48C141.1 48 48 141.1 48 256C48 370.9 141.1 464 256 464C370.9 464 464 370.9 464 256C464 141.1 370.9 48 256 48z" /></svg>
            </S.IconButton>
          </S.Postuler>}
        <S.Button onClick={() => showModalPrice(false)}>Demander un binôme</S.Button>
        <S.Button onClick={() => showModalPrice(false, 1)}>Solo</S.Button>
      </>
        : <S.Button onClick={() => showModalPrice(false)}>Postuler</S.Button>}
    </S.Postulation>}
    {modalPrice.show && <ModalPostulation modal={modalPrice} handlePostulate={handlePostulate} setModal={setModalPrice} />}
  </S.Container >
};

export default TravelCard;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  background: ${({ theme }) => theme.card};
  border-radius: 5px;
  padding: 18px;
  display: flex;
  flex-direction: column;

  .cities {
    font-family: ${({ theme }) => theme.primaryFont};
    text-transform: uppercase;
    font-weight: 600;
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 18px;

    // arrow
    span {
      color: ${({ theme }) => theme.primary};
    }
  }
`

S.Label = styled.span`
  padding: 5px 10px;
  font-size: 14px;
  display: block;
  border-radius: 15px;
  width: fit-content;
  margin-bottom: 10px;
  background: ${({ theme }) => theme.error};
`

S.TimeData = styled.div`
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  flex-wrap: wrap;
  /* -webkit-flex: 1; */
`

S.Options = styled.div`
  display: flex;
  justify-content: space-between;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-top: 8px;

  svg {
    fill: ${({ theme }) => theme.primary};
  }
  .car {
    width: 19px;
    margin-right: 4px;
  }
  .loop {
    width: 12px;
    height: auto;
    margin-right: 10px;
  }
  .car-two {
    width: 17px;
    margin-right: 10px;
  }
  span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary};
  }
`

S.Postulation = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: fill-available;

  @-moz-document url-prefix() {
    height: 100%;
    min-height: fill-available;
  }

  button:first-of-type {
    margin-top: auto;
  }

  .radio {
    margin-bottom: 5px;

    // radio container
    & > div > div {
      gap: 10px;
    }
    label {
      font-size: 13px;
      padding-left: 24px;

      .checkmark {
        width: 16px;
        height: 16px;
        background: ${({ theme }) => theme.navbarIcons};

        ::after {
          background: ${({ theme }) => theme.card};
          top: 4.5px;
          left: 4.5px;
          width: 7px;
          height: 7px;
        }
      }
    }
  }
`

S.Postuler = styled.div`
  display: flex;
  border-radius: 5px 5px !important;

  .formfield_container {
    background: ${({ theme }) => theme.navbarIcons};
    border-radius: 5px 0 0 5px;
    
    select {
      color: ${({ theme }) => theme.textInverse};
    }
    & > div {
      & > div {
        background: ${({ theme }) => theme.navbarIcons};
        border-radius: 5px 0 0 5px;

        ::after {
          border-color: ${({ theme }) => theme.textInverse};
        }
      }
    }
  }
`

S.IconButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  border-radius: 0 5px 5px 0;
  padding: 0 15px;
  filter: ${({ valid }) => valid ? 'unset' : 'contrast(0.5)'};
  cursor: ${({ valid }) => valid ? 'pointer' : 'not-allowed'};
  
  svg {
    fill: ${({ theme }) => theme.textInverse};
    width: 20px;
    height: 20px;
  }
`

S.Button = styled(Button)`
  margin-top: 15px;

  ${({ active }) => active && css`
    border: 1px solid ${({ theme }) => theme.navbarIcons};
  `}
`

S.Applied = styled.p`
  font-size: 15px;
  margin-top: 25px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 5px;
  padding: 5px;
  text-align: center;
`

S.Info = styled.p`
  margin-top: 20px;
  text-align: center;
  color: yellow;

  ${({ theme }) => theme === 'light' && css`
    color: #ff9400;
  `}
`

S.Price = styled.div`
  margin-top: 10px;
  font-size: 15px;

  span {
    display: flex;
    align-items: center;
    gap: 3px;
    height: 25px;
  }

  .loading {
    margin: 0;
    display: inline-block;
    height: 25px;
  }

  svg {
    width: 25px;
    height: 25px;
  }
`