// 
// ─── COPY AN OBJECT AND RETURN THE VALUE ───────────────────────────────────────

import Cookies from "universal-cookie"
import Api from "./Api"

export const copyObject = (originalObject) => {
  return Object.assign({}, originalObject)
}

// 
// ─── STATE DECLARATION ───────────────────────────────────────
//
export const getValue = (object, values) => {
  if (!object) {
    return undefined
  }

  let objectValue = object

  values.map((value) => {
    if (objectValue && objectValue.hasOwnProperty(value)) {
      objectValue = objectValue[value]
    } else {
      objectValue = undefined
    }
  })

  return objectValue
}

// 
// ─── PREPARE FORM DATA BEFORE SUBMIT  ───────────────────────────────────────
//
export const prepareFormData = (opt) => {
  let data = {}, errors = {}, multipleDesc = {}

  Array.from(document.forms[opt.formId]).filter(e => e.getAttribute("name")).forEach((element) => {
    let fieldValue

    if (element.type === 'radio') {
      if (element.checked) {
        fieldValue = { [element.name]: element.value }
      }
    } else if (element.type === 'checkbox') {
      if (element.required && !element.checked) {
        errors[element.name] = { error: true, message: 'Champ obligatoire' };
      } else {
        fieldValue = { [element.name]: element.checked ? true : false }
      }
    } else if (element.type === 'file') {
      fieldValue = { [element.name]: element.files[0] }
      if (!element.files[0] && element.required) {
        errors[element.name] = { error: true, message: 'Fichier manquant' };
      } else {
        fieldValue = { [element.name]: element.files[0] }
      }
    } else if (element.dataset.attribute !== 'multiple') {
      const checkField = validateFields(element)
      if (checkField.error) {
        errors[element.name] = checkField;
      } else {
        fieldValue = { [element.name]: element.value }
      }
      // Traitement spécifique pour les formulaires multiples
    } else if (element.dataset.attribute === 'multiple' && element.value) {
      const elementId = parseInt(element.name.slice(-1))
      let name = element.name.includes('value') ? 'value' : 'label'
      multipleDesc[elementId] = {

        ...multipleDesc[elementId],
        ...{
          [name]: element.value
        }
      }
    }

    data.fields = {
      ...data.fields, ...fieldValue
    }

    return data
  })

  if (opt.multiple) {
    data.fields[opt.multiple] = JSON.stringify(multipleDesc)
  }

  if (Object.keys(errors).length) {
    data.errors = errors
  }

  return data
}

// 
// ─── FORM FIELD VALIDATION ───────────────────────────────────────
//
const validateFields = (opt) => {
  let result = {}

  if (!opt.value && opt.required) {
    result = { error: true, message: 'Champ obligatoire' }
  } else if (opt.type === 'password' && opt.name === 'password_confirmation') {
    const passwordValue = document.querySelector('[name="password"]').value;
    if (!(passwordValue === opt.value)) result = { error: true, message: 'Les mots de passe doivent être identiques' };
  }
  else if (opt.type === 'password' && opt.value && opt.dataset.name === 'password') {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/gm;
    if (!passwordRegex.test(opt.value)) result = { error: true, message: 'Mot de passe invalide' };
  }
  else if (opt.type === 'email') {
    const emailRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (!emailRegex.test(opt.value)) result = { error: true, message: 'Email invalide' };
  } else if (opt.type === 'select-one' && opt.required) {
    if (opt.value == -1) result = { error: true, message: 'Champ obligatoire' }
  } else if (opt.type === 'time' && opt.required) {
    const hours = Number(`${opt.value[0]}${opt.value[1]}`);
    const minutes = Number(`${opt.value[3]}${opt.value[4]}`);

    if (opt.max) {
      const hoursMax = Number(`${opt.max[0]}${opt.max[1]}`)
      if ((hours > hoursMax) || (hours === hoursMax && minutes !== 0)) return { error: true, message: `L'heure ne peut pas dépasser ${opt.max}` }
    }
    if (opt.min) {
      const hoursMin = Number(`${opt.min[0]}${opt.min[1]}`)
      if (hours < hoursMin) return { error: true, message: `L'heure ne peut pas être sous ${opt.min}` }
    }
  } else if (opt.type === 'number' && opt.required) {
    if (opt.max && Number(opt.value) > Number(opt.max)) return { error: true, message: `La valeur ne peut pas dépasser ${opt.max}` }
  }

  return result;
}

//
// ─── FORMAT DATE ───────────────────────────────────────
//
export const formatDate = (opt) => {

  if (!opt.time) return ''

  let d = new Date(opt.time);

  if (opt.add_time) {
    d = new Date(d.getTime() + (opt.add_time * 1000));
    return d;
  }

  let data = {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate(),
    hours: d.getHours(),
    minutes: d.getMinutes(),
    week_day: d.getDay(),
  }

  const monthLabels = ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'];
  const dayLabels = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];

  if (opt.display === 'date_full') {
    data.month = monthLabels[data.month - 1];
    data.week_day = dayLabels[data.week_day];
  }

  Object.entries(data).map(([key, value]) => {
    if ((opt.display === 'date_full' && !['day', 'month'].includes(key)) || opt.display !== 'date_full') {
      return data[key] = (value).toString().padStart(2, 0)
    }
  })

  let year = data.year;
  let month = data.month;
  let day = data.day;
  let hours = data.hours;
  let minutes = data.minutes;
  let week_day = data.week_day;

  let result
  switch (opt.display) {
    case 'hour':
      result = `${hours}h${minutes}`;
      break;
    case 'timer':
      result = `${hours}:${minutes}`;
      break;
    case 'date_full':
      result = capitalizeFirstLetter(`${week_day} ${day} ${month} ${year}`);
      break;
    case 'date_month':
      result = `${monthLabels[data.month - 1]} ${year}`;
      break;
    case 'date':
      result = `${day}/${month}/${year}`;
      break;
    case 'datetimepicker':
      result = `${year}-${month}-${day}`;
      break;
    case 'month':
      result = `${monthLabels[data.month - 1]}`;
      break;
    case 'month_number':
      result = `${data.month}`;
      break;
    case 'day':
      result = `${dayLabels[parseInt(data.week_day)]}`;
      break;
    case 'day_number':
      result = `${data.day}`;
      break;
    case 'week_number':
      result = `${data.week_day}`;
      break;
    default:
      result = `${day}/${month}/${year} - ${hours}h${minutes}`;
      break;
  }

  return result
}

export const formatTime = (opt) => {
  let totalSeconds = opt.duration;
  let hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);

  switch (opt.display) {
    case 'timer':
      return `${(hours).toString().padStart(2, 0)}:${(minutes).toString().padStart(2, 0)}`;
    case 'hour':
      return `${hours ? `${hours}h` : ''}${minutes ? minutes < 10 ? `0${minutes}` : minutes : hours ? '00' : '0'}${hours ? '' : 'min'}`
    default:
      return `${(hours).toString().padStart(2, 0)}:${(minutes).toString().padStart(2, 0)}`;
  }
}

//
// ─── CAPITALIZE FIRST LETTER ───────────────────────────────────────
//
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

//
// ─── GET USERS INITIALS ───────────────────────────────────────
//
export const userInitials = (opt) => {
  return `${getValue(opt, ['firstname', 0])}${getValue(opt, ['name', 0])}`
}

//
// ─── FORMAT DATA ───────────────────────────────────────
//
export const formatData = (opt) => {
  let result
  switch (opt.format) {
    case 'hours':
      result = formatTime({ duration: opt.value, display: 'timer' }) + ' h'
      break;
    case 'price':
      var int2 = new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR", currencyDisplay: "symbol" });
      result = int2.format(opt.value);

      break;
    default:
      result = opt.value;
      break;
  }
  return result;
}

// 
// ─── COPY TEXT TO CLIPBOARD ───────────────────────────────────────
//
export const copyToClipboard = (opt) => {
  let element = opt.element;

  let copyText = document.querySelector(`.${element}`);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  navigator.clipboard.writeText(copyText.value);
}

// 
// ─── AUTOSIZE TEXTEAREA ───────────────────────────────────────
//
export const autoSize = (e) => {
  var el = e.target || e;
  setTimeout(function () {
    el.style.cssText = 'height:auto; padding:0';
    el.style.cssText = 'height:' + el.scrollHeight + 'px';
  }, 0);
}

// 
// ─── LOGOUT FUNCTION ───────────────────────────────────────
//
export const Logout = async () => {
  const cookies = new Cookies();

  const params = { endpoint: '/auth/logout' }
  const logoutUser = await Api(params)

  if (logoutUser && logoutUser.success) {
    cookies.remove(process.env.REACT_APP_USER_COOKIE)
    window.location.href = '/'
  }
}

// 
// ─── GET MISSION PRICE ───────────────────────────────────────
//
export const getPrice = async (opt) => {
  const { duration, recovery, recovery_waiting_time, allSettings, postal_code, driver_hourly_price, isClient = true, separate = false } = opt;
  // const { duration, recovery, recovery_waiting_time, allSettings, postal_code, driver_hourly_price, isClient = true, separate = false, stripe_subscription_enabled } = opt;
  let price, waitingMinutePrice, sbs_margin, defaultHourlyPrice;

  // GET SETTINGS
  const twoDigit = postal_code.substring(0, 2);

  if (allSettings.hasOwnProperty(twoDigit)) {
    // console.log('prix du secteur : ', twoDigit);
    waitingMinutePrice = Number(allSettings[twoDigit].waiting_minute_price);
    sbs_margin = Number(allSettings[twoDigit].sbs_margin);
    defaultHourlyPrice = Number(allSettings[twoDigit].default_hourly_price);
  }
  else {
    // console.log('prix du secteur : ', 0);
    waitingMinutePrice = Number(allSettings['default'].waiting_minute_price);
    sbs_margin = Number(allSettings['default'].sbs_margin);
    defaultHourlyPrice = Number(allSettings['default'].default_hourly_price);
  }

  // CALCULATE PRICE
  let waitingMinutes = 0;
  const minutes = duration / 60;

  if (recovery) {
    const arr = recovery_waiting_time.split(':');
    waitingMinutes = Number(arr[0]) * 60 + Number(arr[1]);
  }

  // driver price
  if (driver_hourly_price) {
    price = minutes * driver_hourly_price + waitingMinutes * waitingMinutePrice;
    const fee = price * sbs_margin / 100;
    // const fee = stripe_subscription_enabled ? 0 : price * sbs_margin / 100;

    if (isClient) {
      if (separate) {
        return {
          amount: price.toFixed(2),
          fee: fee.toFixed(2),
        }
      }

      price = price + price * sbs_margin / 100;
    }

    price = `${price.toFixed(2)}€`;
  }
  // estimation
  else {
    let defaultPrice = minutes * defaultHourlyPrice + waitingMinutes * waitingMinutePrice;

    if (isClient) {
      defaultPrice = parseInt(defaultPrice + defaultPrice * sbs_margin / 100);
    }

    price = `${defaultPrice}€`;
  }

  return price;
}

// 
// ─── SWITCH USER ROLE ───────────────────────────────────────
//
export const switchRole = async (new_role) => {
  const params = { endpoint: '/users/switch', data: { active_role: new_role } }
  const query = await Api(params);

  // reload page to update stored data
  if (query.success) window.location = '/';
}

//
// ─── CHECK IF USER IS IN REUNION ───────────────────────────────────────
//
export const isInReunion = (postal_code) => (postal_code || '').startsWith('97');
// Intl.DateTimeFormat().resolvedOptions().timeZone.includes('Reunion');