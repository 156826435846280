import { Fragment, useEffect, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { prepareFormData, getValue } from '../../contexts/Utils';
import Formfields from './Formfields';
import { Button } from '../StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Steps = (props) => {
  const { fields, handleSubmit, errors, setErrors, data, setData, updateIndex } = props;
  const [index, setIndex] = useState(0);
  const [finalLoading, setFinalLoading] = useState(false);

  useEffect(() => {
    if (updateIndex) updateIndex(index);
  }, [index, updateIndex])

  // 
  // ─── HANDLE FIELDS CHANGE ───────────────────────────────────────
  //
  const handleChange = async (foo) => {
    const newData = prepareFormData({ formId: 'registerForm' });
    const newFields = { ...data, ...newData.fields };
    let errors = {};

    // MANAGE FIELDS ERRORS
    if (newData.errors) errors = newData.errors;

    // custom next
    let customNextRes = {};
    if (foo) {
      customNextRes = await foo(newFields);
      if (customNextRes.custom_errors && Object.keys(customNextRes.custom_errors).length) errors = { ...errors, ...customNextRes.custom_errors };
    }

    if (Object.keys(errors).length) return setErrors(errors);

    // MANAGE NEXT STEP
    if (customNextRes.custom_fields) setData({ ...newFields, ...customNextRes.custom_fields });
    else setData({ ...newFields });
    // confirm
    if (index === fields.length - 1) {
      setFinalLoading(true);
      await handleSubmit(newFields);
      setFinalLoading(false);
      return;
    }

    // next
    setErrors({});
    setIndex(index + 1);
  }

  const handlePrevious = (foo) => {
    if (foo) foo();
    setIndex(index - 1);
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Form name="registerForm">
      {fields[index].map((e, i) => {
        if (e.hasOwnProperty('cond') && !e.cond) return true
        if (e.title) return <h2 key={e.title}>{e.title}</h2>
        if (e.handlePrevious || e.handleNext) return true;
        if (e.custom_component) return <Fragment key={i}>{e.custom_component}</Fragment>
        if (Array.isArray(e)) {
          return <S.InnerFields key={i}>
            {e.map(innerField => {
              if (innerField.hasOwnProperty('cond') && !innerField.cond) return true
              return <Formfields key={innerField.name} field={{ ...innerField, error: getValue(errors, [innerField.name, 'message']), default: data[innerField.name] || innerField.default, noStar: true }} />
            })}
          </S.InnerFields>
        } else {
          return <Formfields key={e.name} field={{ ...e, error: getValue(errors, [e.name, 'message']), default: data[e.name] || e.default, noStar: true }} />;
        }
      })}
    </S.Form>
    <S.ButtonsContainer $step={index}>
      {index !== 0 && <S.Button type="button" $width="calc(50% - 5.5px)" outline={true} onClick={() => handlePrevious(fields[index][0].handlePrevious)}>Précédent</S.Button>}
      <S.Button $loading={finalLoading} $step={index} $width={index === 0 ? '100%' : 'calc(50% - 5.5px)'} $after={index !== fields.length - 1} onClick={() => handleChange(fields[index][0].handleNext)}>
        {index === fields.length - 1 ? props.finalButton ? props.finalButton : "Finaliser" : "Suivant"}
        {finalLoading && <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
          <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 50 50"
              to="360 50 50"
              repeatCount="indefinite" />
          </path>
        </svg>}
      </S.Button>
    </S.ButtonsContainer>
  </S.Container>
};

export default Steps;

// 
// ───  STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  width: 100%;

  h2 {
    font-size: 18px;
  }

  @media (max-width: 800px) {
    h2 {
      font-size: 17px;
    }
  }
`

S.Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 11px;
`

S.InnerFields = styled.div`
`

S.ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${({ $step }) => $step === 0 ? 'flex-end' : 'center'};
  gap: 11px;
`

S.Button = styled(Button)`
  margin-top: 25px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 9px;
  width: ${({ $width }) => $width};
  max-width: ${({ $step }) => $step === 0 ? '100%' : '300px'};
  
  &::after {
    content: "";
    display: ${({ $after }) => $after ? 'block' : 'none'};
    border-right: 1px solid ${({ theme }) => theme.textInverse};
    border-top: 1px solid ${({ theme }) => theme.textInverse};
    width: 8px;
    height: 8px;
    transform: rotate(45deg) translate(-1px, 1px);
  }

  ${({ $loading }) => $loading && css`
    pointer-events: none;
    opacity: .8;
    gap: 5px;
  `}

  svg {
    width: 33px;
    height: 33px;
    fill: ${({ theme }) => theme.textInverse};
  }
`