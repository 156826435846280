import { useState, useEffect, useRef, useContext } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Context } from "../../App";
import Api from '../../contexts/Api';
import Loader from '../generic/Loader';
import Cookies from "universal-cookie";

const AppData = () => {
  const { setSettings, setAllSettings } = useContext(Context);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const firstLoad = useRef(true);
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);

  //
  // ─── MANAGE APP CONNECTION ───────────────────────────────────────
  //
  useEffect(() => {

    //
    // ─── CHECK AUTHENTICATION ───────────────────────────────────────
    //
    const fetchData = async () => {
      const promisedArray = [Api({ endpoint: `/settings`, method: 'GET', query: { postal_code: user.postal_code } })]
      if (firstLoad.current) promisedArray.push(Api({ endpoint: `/data`, method: 'GET' }))

      Promise.all(promisedArray)
        .then(([settings, app_data]) => {
          //
          // ─── WINDOW GLOBAL VARIABLES AND DATA SETUP ───────────────────────────────────────
          //
          if (firstLoad.current) {
            window._DATA = {};
            window._DATA.types = app_data.data.types;
            firstLoad.current = false;
          }

          setSettings(settings.data.settings);
          setAllSettings(settings.data.all_settings);

          //
          // ─── ALLOW TO ACCESS APP ───────────────────────────────────────
          //
          // 500ms min before loader hidden
          new Promise(resolve => {
            setTimeout(() => {
              resolve(setLoading(false));
            }, 500);
          })
        });
    }

    fetchData()
  }, [location, setSettings, user.postal_code])

  return !loading ? <Outlet /> : <Loader />
}

export default AppData;