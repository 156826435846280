import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Api from '../../contexts/Api';
import { getValue, prepareFormData } from '../../contexts/Utils';
import Formfields from './Formfields';
import { useNotification } from '../UseNotification';
import { Button } from '../StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const VehiclesManagement = (props) => {
  const { data, setData, errors, setErrors, edit = true, selectRequired = false, defaultValue = -1, form = true } = props;
  const { notification } = useNotification();
  const [selected, setSelected] = useState(-1);
  const [newVehicle, setNewVehicle] = useState(false);
  const horsepowerTypes = Object.values(window._DATA.types.car_horsepower);

  useEffect(() => {
    if (selected !== defaultValue) {
      setSelected(defaultValue);
    }
  }, [defaultValue])

  // 
  // ─── VEHICLES FUNCTIONS ───────────────────────────────────────
  //
  const handleSelectChange = (e) => {
    setSelected(e)
    setNewVehicle(false)
  }

  // add / update a vehicle
  const handleVehiculeSubmit = async () => {
    const formData = prepareFormData({ formId: 'vehicle-form' });
    if (formData.errors) return setErrors(formData.errors)
    else setErrors({});

    const query = await updateVehicles(formData.fields);

    // notification
    let message;
    if (newVehicle) message = query.success ? 'Véhicule ajouté' : "Le véhicule n'a pas pu être ajouté"
    else message = query.success ? 'Véhicule mis à jour' : "Le véhicule n'a pas pu être mis à jour"
    notification({ variant: query.success ? 'success' : 'error', message })

    // update states
    if (query.success) {
      const newObj = { ...data.vehicles, [query.user_vehicle_id]: { ...formData.fields, horsepower: parseInt(formData.fields.horsepower), user_vehicle_id: query.user_vehicle_id } };

      const newData = { ...data };
      newData.vehicles = newObj;
      setData({ ...newData });

      if (newVehicle) setSelected(query.user_vehicle_id);
      setNewVehicle(false);
    }
  }

  // 
  // ─── UPDATE USER VEHICLE IN DB ───────────────────────────────────────
  //
  const updateVehicles = async (fields) => {
    const queryData = { fields: { ...fields, user_id: data.user_id } }
    if (!newVehicle) queryData.user_vehicle_id = selected;

    const params = {
      data: queryData,
      endpoint: '/users/vehicles',
      method: newVehicle ? 'POST' : 'PUT'
    }
    const query = await Api(params);
    return query;
  }

  // 
  // ─── DELETE A VEHICLE ───────────────────────────────────────
  //
  const handleVehiculeDelete = async () => {
    const newObj = { ...data.vehicles };
    delete newObj[selected];

    setSelected(-1);
    setData({ ...data, vehicles: newObj })

    const params = {
      data: { user_vehicle_id: selected },
      endpoint: '/users/vehicles',
      method: 'DELETE'
    }
    const query = await Api(params);

    const message = query.success ? 'Véhicule supprimé' : "Le véhicule n'a pas pu être supprimé"
    notification({ variant: query.success ? 'success' : 'error', message })
  }

  // 
  // ─── DISPLAY EMPTY FIELDS TO ADD A NEW VEHICLE ───────────────────────────────────────
  //
  const addVehicule = () => {
    setNewVehicle(true);
    setSelected(-1);
  }

  // 
  // ─── FORMFIELDS ───────────────────────────────────────
  //
  let vehicleFormFields = [
    { component: "text", name: "brand", label: "Marque du véhicule", required: true },
    { component: "text", name: "type", label: "Type du véhicule", required: true },
    { component: "select", name: "horsepower", label: "CV", data: horsepowerTypes, required: true },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    {data.vehicles && Object.keys(data.vehicles).length > 0 && <Formfields field={{ component: "select", name: "vehicles", required: selectRequired, onChange: handleSelectChange, label: "Sélectionner un véhicule", default: selected, error: !getValue(errors, ['vehicles', 'message']) ? getValue(errors, ['vehicles', 'message']) : '', data: Object.values(data.vehicles).map((e) => ({ id: e.user_vehicle_id, label: `${e.brand} ${e.type} ${horsepowerTypes[e.horsepower].label}` })) }} />}
    {(selected != -1 || newVehicle) && (edit || newVehicle) && <S.Vehicle as={form ? 'form' : 'div'} id={form ? 'vehicle-form' : ''} onSubmit={handleVehiculeSubmit}>
      {vehicleFormFields.map(e => {
        let defaultValue = newVehicle ? '' : data.vehicles[selected][e.name];
        if (defaultValue == undefined && e.component === "select") defaultValue = -1
        return <Formfields key={e.name} field={{ ...e, form: 'vehicle-form', default: defaultValue, noStar: true, error: getValue(errors, [e.name, 'message']) }} />
      })}
      <div>
        {!newVehicle && edit && <S.Button type='button' onClick={handleVehiculeDelete} error={true}>Supprimer</S.Button>}
        <S.Button onClick={handleVehiculeSubmit} type="button">{newVehicle ? "Ajouter" : "Enregistrer"}</S.Button>
      </div>
    </S.Vehicle>}
    {!newVehicle && <div className='button'>
      <div></div>
      <S.Button type='button' onClick={addVehicule}>Ajouter un véhicule</S.Button>
    </div>}
    {getValue(errors, ['error_select_vehicle', 'message']) && <p className='error'>{getValue(errors, ['error_select_vehicle', 'message'])}</p>}
  </S.Container>
};

export default VehiclesManagement;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  width: 100%;

  .error {
    color: ${({ theme }) => theme.error};
    font-size: 14px;
    margin-top: 5px;
  }
`

S.Button = styled(Button)`
  height: fit-content;
  margin-top: 12px;
`

S.Vehicles = styled.div`
  h2 {
    margin-bottom: 15px;
  }

  .button {
    display: flex;
    width: 100%;

    @media(min-width: 520px) {
      gap: 11px;
      & > button, & > div {
        flex: 1;
      }
      & > button {
        padding: 10px 0;
      }
    }
  }
`

S.Vehicle = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  gap: 11px;

  // buttons
  div:last-child {
    display: flex;
    gap: 11px;
  }
`