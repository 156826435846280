import styled, { css } from 'styled-components/macro';
import { Button } from '../StyledComponents';

const LoadingButton = ({ loading, onClick, children }) => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Button onClick={onClick} $loading={loading}>
    {children}
    {loading && <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
      <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite" />
      </path>
    </svg>}
  </S.Button>
};

export default LoadingButton;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Button = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  ${({ $loading }) => $loading && css`
    pointer-events: none;
    opacity: .8;
  `}

  svg {
    width: 33px;
    height: 33px;
    fill: ${({ theme }) => theme.textInverse};
  }
`