import { useRef } from 'react';
import styled from 'styled-components/macro';
import { Button } from './StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalRefusal = (props) => {
  const { modal, setModal, handleDelete } = props;
  const textarea = useRef(null);

  const closeModal = () => {
    setModal({
      show: false
    })
  }

  const handleValidate = () => {
    const reason = textarea.current.value;
    const { user_id, firstname, email } = modal;

    handleDelete({ user_id, send_mail: true, reason, firstname, email });
    closeModal();
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content>
      <p>Indiquer un motif de refus</p>
      <textarea ref={textarea} placeholder="Motif de refus" />
      <S.Buttons>
        <Button onClick={closeModal} outline={true}>Annuler</Button>
        <Button onClick={handleValidate}>Valider</Button>
      </S.Buttons>
    </S.Content>
    <S.Background onClick={closeModal}></S.Background>
  </S.Container>
};

export default ModalRefusal;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
`

S.Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 23%);
  padding: 20px;
  max-width: 500px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  z-index: 10;

  textarea {
    margin-top: 10px;
    width: 100%;
    height: 130px;
    border: 1px solid black;
    border-radius: 5px;
    resize: none;
    font-family: ${({ theme }) => theme.primaryFont};
    padding: 10px;
    box-sizing: border-box;
    font-size: 15px;

    :focus {
      outline: none;
    }
  }
`

S.Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 160px;
  }
`

S.Background = styled.div`
  inset: 0;
  position: fixed;
  background: #000000a3;
`