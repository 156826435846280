import { useRef } from 'react';
import styled from 'styled-components/macro';
import { Button } from './StyledComponents';
import Formfields from './form/Formfields';
import Api from '../contexts/Api';
import { useNotification } from './UseNotification';
import Cookies from 'universal-cookie';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalDispute = (props) => {
  const { notification } = useNotification();
  const { modal, setModal } = props;
  const textarea = useRef(null);
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);

  const closeModal = () => {
    setModal({
      show: false
    })
  }

  const handleValidate = async () => {
    const courseAccomplished = document.querySelector('[name="course_accomplished"]').checked ? 'Oui' : 'Non';
    const reason = textarea.current.value;
    if (!reason) return notification({ variant: 'error', message: 'Veuillez décrire votre problème' });
    const mission_id = modal.mission_id;

    const params = {
      endpoint: '/missions/litige',
      method: 'POST',
      data: {
        courseAccomplished,
        reason,
        mission_id,
        clientId: user.user_id,
        clientName: user.name,
        clientFirstname: user.firstname,
      }
    }
    const query = await Api(params);

    notification({ variant: query.success ? 'success' : 'error', message: query.message });
    if (query.success) closeModal();
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content>
      <p>Indiquer un motif de litige</p>
      <Formfields field={{ component: 'checkbox', name: 'course_accomplished', label: 'La course a eu lieu' }} />
      <textarea ref={textarea} placeholder="Description du litige*" required={true} />
      <S.Buttons>
        <Button onClick={closeModal} outline={true}>Annuler</Button>
        <Button onClick={handleValidate}>Valider</Button>
      </S.Buttons>
    </S.Content>
    <S.Background onClick={closeModal}></S.Background>
  </S.Container>
};

export default ModalDispute;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
`

S.Content = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 23%);
  padding: 20px;
  max-width: 500px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  z-index: 1000;

  textarea {
    margin-top: 10px;
    width: 100%;
    height: 130px;
    border: 1px solid black;
    border-radius: 5px;
    resize: none;
    font-family: ${({ theme }) => theme.primaryFont};
    padding: 10px;
    box-sizing: border-box;
    font-size: 15px;

    :focus {
      outline: none;
    }
  }

  p {
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 20px;
  }
`

S.Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;

  button {
    width: 160px;
  }
`

S.Background = styled.div`
  inset: 0;
  z-index: 999;
  position: fixed;
  background: #000000a3;
`