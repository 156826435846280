import { useState } from "react";
import styled from "styled-components";
import { CheckboxFieldStyle } from "./Formfields";

const CGUCheck = (props) => {
  const [checked, setChecked] = useState(false);
  const [modalActive, setModalActive] = useState(false);

  const handleChange = () => {
    setChecked(prev => !prev);
  }

  const handleModal = () => {
    setModalActive(prev => {
      document.body.style.overflow = prev ? 'unset' : 'hidden';
      return !prev
    })
  }

  const Modal = () => <S.Modal>
    <div onClick={handleModal}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" /></svg>
    </div>
    {/* <CGU /> */}
  </S.Modal>

  return <S.Container>
    <S.CheckboxFieldStyle error={props.error ? true : false}>
      <label htmlFor={props.name}>
        <input type="checkbox" checked={checked} onChange={handleChange} name="cgu" required={true} />
        J'accepte les
      </label>
      <a className="cgu-link" href="https://www.saint-bernard-services.com/conditions-generales-dutilisation/" target="_blank" rel="noopener noreferrer">CGU*</a>
    </S.CheckboxFieldStyle>
    {props.error && <span className="error">{props.error}</span>}
    {
      modalActive && <>
        <Modal />
        <S.Background onClick={handleModal} />
      </>
    }
  </S.Container >
}

export default CGUCheck;

const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 100%;
  display: flex;
  margin-top: 20px;

  .cgu-link {
    cursor: pointer;
    color: ${({ theme }) => theme.primary};
  }

  .error {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme }) => theme.error};
  }
`

S.CheckboxFieldStyle = styled(CheckboxFieldStyle)`
  display: flex;
  flex-direction: row;
  gap: 7px;
  align-items: flex-end;

  label input {
    background-color: unset;
  }
`

S.Modal = styled.div`
  z-index: 300;
  position: fixed;
  width: 90%;
  min-height: calc(100svh - 140px);
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: ${({ theme }) => theme.card};
  overflow-y: auto;
  border-radius: 5px;

  > div:first-child {
    width: 100%;
    padding-top: 20px;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    box-sizing: border-box;

    & svg {
      fill: ${({ theme }) => theme.text};
      height: 25px;
    }
  }

  @media(min-width: 800px) {
    top: 90px;
  }
`

S.Background = styled.div`
  z-index: 299;
  inset: 0;
  position: fixed;
  background: #000000a3;
`