import { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Cookies from 'universal-cookie';
import Api from '../contexts/Api';
import TravelCard from '../components/TravelCard';
import noMissions from '../assets/no-missions.png';
import driverSleep from '../assets/driver-sleep.png';
import Loading from '../components/generic/Loading';
import PostulationWarning from '../components/PostulationWarning';
import { Context } from '../App';
import ModalExcel from '../components/ModalExcel';
import { Button } from '../components/StyledComponents';
import { getValue } from '../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Travels = (props) => {
  const cookies = new Cookies();
  const { settings } = useContext(Context);
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const [missions, setMissions] = useState({});
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModalExcel, setShowModalExcel] = useState(false);
  const [proposals, setProposals] = useState({});
  const statusTypes = window._DATA.types.mission_status;
  const isDisabled = user.disabled === 1;
  // const mustHaveSubscription = user.driver_type === 2 || (user.driver_type === 1 && user.limit_amount_reached === 1);
  // const hasSubscribed = user.stripe_subscription_enabled === 1;
  const canPostulate = props.canPostulate && !isDisabled && user.status === 2 && user.active_role === 3 && Boolean(user.stripe_charges_enabled)
  // const canPostulate = props.canPostulate && !isDisabled && user.status === 2 && user.active_role === 3 && Boolean(user.stripe_charges_enabled) && (mustHaveSubscription ? hasSubscribed : true) ? true : false;

  let title;
  switch (window.location.pathname) {
    case '/mes-missions':
      title = 'Mes missions';
      break;
    case '/trajets':
      title = 'Trajets';
      break;
    case '/nouvelles-courses':
      title = 'Nouvelles courses';
      break;
    case '/':
      title = 'Accueil';
      break;
    default:
      break;
  }

  // 
  // ─── GET DATA FROM DB ───────────────────────────────────────
  //
  useEffect(() => {
    const getMissions = async () => {
      const query = { byStatus: true }
      if (props.mission_user_get) query.user_get = props.mission_user_get;
      if (props.canPostulate) {
        if (user.role === 3) query.not_current_user = true;
        query.role = user.active_role;
        query.postal_code = user.postal_code;
        query.driver_type = user.driver_type;
      }
      if (props.status) query.status = props.status;
      if (user.role === 4) {
        query.postal_code = user.postal_code
        query.role = 4
      }
      // // if driver has not subscribed -> has time before he can see it
      // if (user.active_role === 3 && user.stripe_subscription_enabled === 0) {
      //   query.time_before_mission_display = settings.time_before_mission_display;
      // }

      const params = {
        query,
        endpoint: '/missions',
        method: 'GET'
      }

      const missionsQuery = await Api(params);

      // update state for admins page
      if ([1, 4].includes(user.role)) {
        setMissions(missionsQuery.success ? missionsQuery.data : {});
        setLoading(false);
      }
      else return missionsQuery.success ? missionsQuery.data : {};
    }

    const getClientMissions = async () => {
      // GET PROPOSALS
      const proposalsParams = {
        query: { driver_approval: 1, teammate_approval: 1, mission_user_id: user.user_id, soloForClient: true },
        endpoint: '/proposals',
        method: 'GET',
      }

      Promise.all([getMissions(), Api(proposalsParams)]).then(([missions, proposals]) => {
        if (proposals.success) {
          // sort proposals by mission
          const sortedProposals = Object.values(proposals.data).reduce((final, current) => {
            if (final.hasOwnProperty(current.mission_id)) final[current.mission_id].push(current);
            else final[current.mission_id] = [current];
            return final;
          }, {});

          setProposals(sortedProposals);
        }

        setMissions(missions);
        setLoading(false);
      });
    }

    // 
    // ─── MES MISSIONS CHAUFFEUR ───────────────────────────────────────
    //
    const getDriverTravelsData = async () => {
      // GET PROPOSALS
      let query = { driver_id: user.user_id, invited_driver_id: JSON.stringify([user.user_id]), withMissions: true, subtype: true }
      if (props.status) query.status = props.status;

      const params = {
        endpoint: '/proposals',
        method: 'GET',
        query
      }

      const proposalsQuery = await Api(params);

      if (proposalsQuery.success) {
        let missionsObj = {}, proposalsObj = {};

        // create mission & proposals object
        Object.entries(proposalsQuery.data).forEach(([status, mission]) => {
          missionsObj[status] = {};

          Object.entries(mission).forEach(([mission_id, proposals]) => {
            missionsObj[status][mission_id] = proposals[0];
            proposalsObj[mission_id] = proposals;
          });
        });

        setMissions(missionsObj);
        setProposals(proposalsObj);
      }

      setLoading(false);
    }

    // 
    // ─── NOUVELLES COURSES ───────────────────────────────────────
    //
    const getNewTravelsData = async () => {
      // GET PROPOSALS
      const proposalsParams = {
        query: { driver_approval: 0, driver_id: user.user_id, invited_driver_id: JSON.stringify([user.user_id]), subtype: true },
        endpoint: '/proposals',
        method: 'GET',
      }

      // GET TEAM
      const teamParams = {
        query: { user_get: user.user_id, accepted: 1 },
        endpoint: '/teams',
        method: 'GET'
      }

      const promiseArray = [getMissions(), Api(proposalsParams)];
      if (user.driver_type === 1) promiseArray.push(Api(teamParams));

      Promise.all(promiseArray).then(([missions, proposals, team]) => {
        // format for select
        if (proposals.success) {
          const arr = Object.values(proposals.data).reduce((final, current) => {
            if (!final.hasOwnProperty(current.mission_id)) final[current.mission_id] = [];
            final[current.mission_id].push({ ...current, id: current.driver, label: current.driver })
            return final;
          }, {})
          setProposals(arr);
        }

        // format for select
        if (team && team.success) {
          const arr = Object.values(team.data).reduce((final, current) => {
            final[current.team_id] = { id: current.team_id, label: user.email === current.driver ? current.invited_driver : current.driver }
            return final;
          }, {})
          setTeam(arr);
        };

        setMissions(missions);
        setLoading(false);
      });
    }

    if (props.mission_user_get) getClientMissions();
    if (props.proposal_user_get) getDriverTravelsData();
    if (props.canPostulate) getNewTravelsData();
    if ([1, 4].includes(user.role)) getMissions();
  }, [user.active_role, user.user_id, user.email, user.role, props.status, props.mission_user_get, props.proposal_user_get, props.canPostulate, user.driver_type, user.postal_code])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  if (!missions || Object.keys(missions).length === 0) return <S.Container>
    {title && <h1>{title}</h1>}
    <S.NoMissions>
      <Loading loading={loading}>
        <img src={user.active_role === 2 ? driverSleep : noMissions} alt='Pas de missions' />
        <p>Aucune mission !</p>
      </Loading>
    </S.NoMissions>
  </S.Container>
  return <div>
    <S.Container>
      {showModalExcel && <ModalExcel onClose={() => setShowModalExcel(false)} />}
      {title && <h1>{title}</h1>}
      {props.canPostulate && !canPostulate && user.active_role === 3 && <PostulationWarning />}
      {Object.entries(missions).map(([key, value]) => (
        <Fragment key={key}>
          <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
            {!props.status && <h2>{getValue(statusTypes, [key, 'label'])}</h2>}
            {!props.status && Number(key) === 3 && <S.Button onClick={() => setShowModalExcel(true)}>Exporter</S.Button>}
          </div>
          <S.Category>
            {Object.values(value).map((mission, i) => {
              const length = Object.values(value).length;
              if (props.canPostulate) {
                // if (proposals.hasOwnProperty(mission.mission_id) && proposals[mission.mission_id].length) {
                //   const userApplied = proposals[mission.mission_id].some(proposal => proposal.driver === user.email || proposal.invited_driver === user.email)
                //   if (userApplied) return null;
                // }
              }

              return (
                <Fragment key={mission.mission_id || mission.proposal_id}>
                  <TravelCard
                    mission={mission}
                    setMissions={setMissions}
                    team={team}
                    proposals={proposals.hasOwnProperty(mission.mission_id) ? proposals[mission.mission_id] : props.proposal_user_get ? mission[0] : []}
                    canPostulate={canPostulate}
                  />
                  {length === i + 1 && length % 2 === 1 && <S.EmptyDiv></S.EmptyDiv>}
                </Fragment>
              )
            })}
          </S.Category>
        </Fragment>
      ))}
    </S.Container>
  </div>
};

export default Travels;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  
  h1 {
    margin-bottom: 20px;
  }

  @media(min-width: 800px) {
    margin-top: 0;
  }
`

S.Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  margin-top: 20px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media(min-width: 800px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
    background: none;
    border-radius: unset;
  }
`

S.EmptyDiv = styled.div`
  display: none;

  @media(min-width: 800px) {
    display: block;
  }
`

S.NoMissions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 30px;
  font-size: 17px;

  img {
    width: 170px;
  }
`

S.Button = styled(Button)`
  width: fit-content;
  height: fit-content;
`