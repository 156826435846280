/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components/macro";
import { getValue, formatDate } from '../../contexts/Utils';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ButtonGroup from "@mui/material/ButtonGroup";
import Autocomplete from '@mui/material/Autocomplete';

// 
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Text = (props) => {
  const defaultValue = getValue(props, ['default']) || props.value || ''
  const [value, setValue] = useState(defaultValue);
  const [passwordValid, setPasswordValid] = useState(false);
  const onLoad = useRef(true);
  const { checkPassword = true } = props;
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/gm;

  const handleChange = (e) => {
    setValue(e.target.value);
    if (props.handleChange) {
      props.handleChange(e.target.value)
    }
  };

  // valid css when at last one character for email
  const handleBlur = (e) => {
    const el = e.currentTarget;
    if (el.value.length > 0) el.classList.add('active');
    else el.classList.remove('active');
  }

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  // 
  // ─── WAITS THAT USER STOPS TYPING ───────────────────────────────────────
  //
  useEffect(() => {
    // 
    // ─── HANDLE PASSWORD CHANGE ───────────────────────────────────────
    //
    const handlePasswordChange = async () => {
      // dont check the empty input on page load
      if (onLoad.current) return onLoad.current = false;

      if (value.match(passwordRegex)) return setPasswordValid(true);
      setPasswordValid(false);
    }

    if (props.type === 'password' && checkPassword) {
      const delay = setTimeout(() => {
        handlePasswordChange();
      }, 500)

      return () => clearTimeout(delay)
    }
  }, [value])

  const handlePassword = (e) => {
    e.currentTarget.classList.toggle('active');
    const isActive = e.currentTarget.classList.contains('active');
    document.querySelector(`#${props.name}`).setAttribute('type', isActive ? "text" : "password")
  }

  // multiline ???
  return <S.TextField error={props.error ? true : false} password={props.type === 'password' ? 1 : 0} disabled={props.disabled} marginTop={props.marginTop}>
    <div>
      <input
        value={value}
        onChange={handleChange}
        type={props.type}
        name={props.name}
        id={props.name}
        form={props.form}
        placeholder={props.placeholder}
        required={props.required}
        onBlur={(e) => props.type === "email" && handleBlur(e)}
        min={props.min}
        max={props.max}
        step={props.step}
        data-name={props.dataName}
      />
      {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
      {props.type === 'password' && <S.PasswordEye onClick={handlePassword}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" /></svg>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zm151 118.3C226 97.7 269.5 80 320 80c65.2 0 118.8 29.6 159.9 67.7C518.4 183.5 545 226 558.6 256c-12.6 28-36.6 66.8-70.9 100.9l-53.8-42.2c9.1-17.6 14.2-37.5 14.2-58.7c0-70.7-57.3-128-128-128c-32.2 0-61.7 11.9-84.2 31.5l-46.1-36.1zM394.9 284.2l-81.5-63.9c4.2-8.5 6.6-18.2 6.6-28.3c0-5.5-.7-10.9-2-16c.7 0 1.3 0 2 0c44.2 0 80 35.8 80 80c0 9.9-1.8 19.4-5.1 28.2zm9.4 130.3C378.8 425.4 350.7 432 320 432c-65.2 0-118.8-29.6-159.9-67.7C121.6 328.5 95 286 81.4 256c8.3-18.4 21.5-41.5 39.4-64.8L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5l-41.9-33zM192 256c0 70.7 57.3 128 128 128c13.3 0 26.1-2 38.2-5.8L302 334c-23.5-5.4-43.1-21.2-53.7-42.3l-56.1-44.2c-.2 2.8-.3 5.6-.3 8.5z" /></svg>
      </S.PasswordEye>}
    </div>
    {props.tip && ((props.type === 'password' && !passwordValid) || props.type !== 'password') && <span className="tip">{props.tip}</span>}
    {props.error && <span>{props.error}</span>}
  </S.TextField>
}

// 
// ─── SWITCH ───────────────────────────────────────
//
export const SwitchField = (opt) => {

  const field = opt.field
  const defaultValue = getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [selected, setSelected] = useState(defaultValue)

  useEffect(() => {
    if (defaultValue !== selected) {
      setSelected(defaultValue)
    }
  }, [defaultValue])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  return (
    <>
      <FormControlLabel
        control={
          <Switch checked={selected ? true : false} value={selected} onChange={() => {
            let value = selected === 0 ? 1 : 0
            setSelected(value)
            if (field.handleChange) {
              field.handleChange(value)
            }
          }} name={field.name} />
        }
        label={field.label || ''}
      />
    </>
  )
}

// 
// ─── RADIO ───────────────────────────────────────
//
export const RadioField = (props) => {
  const defaultValue = getValue(props, ['default']) || 1;
  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    const newValue = parseInt(event.target.value);
    setValue(newValue);
    if (props.onChange) props.onChange(newValue);
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  return <S.RadioField error={props.error ? true : false} direction={props.direction ? props.direction : 'row'}>
    {props.label && <span>{props.label}{props.required && !props.noStar && '*'}</span>}
    <div>
      {props.value.map((radio) => (
        <S.Radio key={radio.value} htmlFor={`${props.name}_${radio.value}`}>
          <input type='radio' checked={value === radio.value} value={radio.value} id={`${props.name}_${radio.value}`} name={props.name} onChange={handleChange} />
          {radio.label}
          <span className="checkmark"></span>
        </S.Radio>
      ))}
    </div>
    {props.error && <span>{props.error}</span>}
  </S.RadioField>
}

// 
// ─── SELECT ───────────────────────────────────────
//
export const SelectField = (props) => {
  let defaultValue, defaultProps = getValue(props, ['default']);
  if (defaultProps !== undefined && defaultProps !== "") defaultValue = defaultProps
  else defaultValue = -1;

  defaultValue = isNaN(defaultValue) ? defaultValue : parseInt(defaultValue);
  const [select, selectValue] = useState(defaultValue);

  const handleChange = (event) => {
    selectValue(event.target.value);
    if (props.onChange) props.onChange(event.target.value);
  };

  useEffect(() => {
    if (defaultValue !== undefined && select !== defaultValue) {
      selectValue(defaultValue)
    }
  }, [defaultValue])

  return <S.SelectField error={props.error ? true : false}>
    <S.Select className="select-container">
      <select form={props.form} value={select} onChange={handleChange} name={props.name} required={props.required}>
        <option value={-1}>{props.label}</option>
        {props.data.map((value) => (
          <option key={value.id} value={value.id}>{value.label}</option>
        ))}
      </select>
    </S.Select>
    {props.tip && <span className="tip">{props.tip}</span>}
    {props.error && <span>{props.error}</span>}
  </S.SelectField>
}

// 
// ─── SELECT WITH LABEL ───────────────────────────────────────
//
export const SelectFieldWithLabel = (props) => {
  let defaultValue, defaultProps = getValue(props, ['default']);
  if (defaultProps !== undefined && defaultProps !== "") defaultValue = defaultProps
  else defaultValue = -1;

  defaultValue = isNaN(defaultValue) ? defaultValue : parseInt(defaultValue);
  const [select, selectValue] = useState(defaultValue);

  const handleChange = (event) => {
    selectValue(event.target.value);
    if (props.onChange) props.onChange(event.target.value);
  };

  useEffect(() => {
    if (defaultValue !== undefined && select !== defaultValue) {
      selectValue(defaultValue)
    }
  }, [defaultValue])

  return <S.SelectFieldWithLabel error={props.error ? true : false}>
    {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
    <S.Select className="select-container">
      <select form={props.form} value={select} onChange={handleChange} name={props.name} required={props.required}>
        <option value={-1}>{props.label}</option>
        {props.data.map((value) => (
          <option key={value.id} value={value.id}>{value.label}</option>
        ))}
      </select>
    </S.Select>
    {props.tip && <span className="tip">{props.tip}</span>}
    {props.error && <span>{props.error}</span>}
  </S.SelectFieldWithLabel>
}

// 
// ─── CHECKBOX ───────────────────────────────────────
//
export const CheckboxField = (props) => {
  const [checked, setChecked] = useState(props.default || false)

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (props.onChange) props.onChange(event.target.checked);
  };

  return <S.CheckboxField error={props.error ? true : false}>
    <label htmlFor={props.name}>
      <input type="checkbox" checked={checked} required={props.required} onChange={handleChange} name={props.name} disabled={props.disabled} />
      {props.label}{props.required && !props.noStar && '*'}
    </label>
    {props.tip && <span className="tip">{props.tip}</span>}
    {props.error && <span className="error">{props.error}</span>}
  </S.CheckboxField>
}

// 
// ─── MULTIPLE ───────────────────────────────────────
//
export const Multiple = (opt) => {
  const field = opt.field
  const fieldName = field.name
  const titles = field.titles
  const defaults = getValue(field, ['default'])

  const componentValue = getValue(field, ['components', 'value'])
  const componentLabel = getValue(field, ['components', 'label'])
  const componentLabelType = getValue(field, ['components', 'label_type'])

  // 
  // ─── CHAMPS NECESSAIRES ───────────────────────────────────────
  //
  let multipleFields = {}

  if (defaults) {
    Object.values(JSON.parse(defaults)).forEach((el, index) => {
      const elementId = `${field.name}_${index}`
      multipleFields[elementId] = [
        { type: componentLabelType, component: componentLabel, label: titles.label, value: el.label, name: `${field.name}_label_${index}` },
        { type: "text", component: componentValue, label: titles.value, value: el.value, name: `${field.name}_value_${index}`, multiline: field.multiline, hidden: field.hidden }
      ]
    })
  } else {
    multipleFields = {
      1: [
        { type: componentLabelType, component: componentLabel, label: titles.label, name: `${fieldName}_label_1` },
        { type: "text", component: componentValue, label: titles.value, name: `${fieldName}_value_1`, multiline: field.multiline, hidden: field.hidden }
      ]
    }
  }

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [fields, setFields] = useState(multipleFields)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const S = {}
  S.MultipleContainer = styled.div`
    display: flex;
    flex-direction: column;
  `

  S.MultipleFields = styled.div`
    display: flex;
    margin-top: 20px;
  `

  S.Button = styled(Button)`
    margin-top: 20px !important;
  `

  return (
    <div>
      <label>{field.label}</label>
      <S.MultipleContainer className="form__multiple">
        {Object.values(fields).map((element, index) => {
          return <S.MultipleFields key={index}>
            {
              element.map((el, i) => {
                return <Formfields key={i} field={{ ...el, ...{ dataSet: { 'data-attribute': 'multiple', 'data-name': fieldName } } }} classNameOuter={i === 0 ? 'input-right' : 'input-left'} />
              })
            }
          </S.MultipleFields>
        })}
      </S.MultipleContainer>
      {!field.removeAdd && <S.Button variant="contained" onClick={(event) => {
        event.preventDefault();
        let index = Object.keys(fields).length + 1;

        // 
        // ─── AJOUT D'UN ELEMENT SUPPLEMENTAIRE A LA LISTE ───────────────────────────────────────
        //
        setFields(fields[index] = {
          ...fields,
          [`${field.name}_${index}`]: [
            { type: componentLabelType, component: componentLabel, label: titles.label, name: `${fieldName}_label_${index}` },
            { type: "text", component: componentValue, label: titles.value, name: `${fieldName}_value_${index}`, multiline: field.multiline, hidden: field.hidden }
          ]
        })
      }}>Ajouter</S.Button>}
    </div>
  )
}

// 
// ─── UPLOAD FILE ───────────────────────────────────────
//
export const UploadFile = (props) => {
  const [file, setFile] = useState(props.default || { name: '' })

  const handleChange = (e) => {
    setFile({ name: e.target.files[0].name })
    if (props.onChange) {
      props.onChange(e)
    }
  }

  return <S.UploadFile error={props.error ? true : false}>
    <label htmlFor={props.name}>
      {props.label}{props.required && !props.noStar && '*'}
      <span className="download">Télécharger</span>
    </label>
    <input type="file" name={props.name} id={props.name} required={props.required} onChange={handleChange} />
    {file.name && <p>{file.name}</p>}
    {!file.name && props.error && <span>{props.error}</span>}
  </S.UploadFile>
}

// 
// ─── DATE AND TIME PICKER ───────────────────────────────────────
//
const DatePicker = (props) => {
  let defaultDate = props.default === 'none' ? '' : formatDate({ time: props.default || Date.now(), display: 'datetimepicker' });
  const [date, setDate] = useState(defaultDate);

  const displayDatePicker = () => {
    document.getElementById(props.name).showPicker();
  }

  const handleChange = (e) => {
    setDate(e.target.value);
    if (props.handleChange) {
      props.handleChange(e.target.value)
    }
  };

  return <S.DatePicker error={props.error ? true : false}>
    <div>
      <input
        type="date"
        name={props.name}
        id={props.name}
        required={props.required}
        value={date}
        onChange={handleChange}
        className="active"
      />
      {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
      <svg onClick={displayDatePicker} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H64C28.7 64 0 92.7 0 128v16 48V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V192 144 128c0-35.3-28.7-64-64-64H344V24c0-13.3-10.7-24-24-24s-24 10.7-24 24V64H152V24zM48 192H400V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V192z" /></svg>
    </div>
    {props.error && <span>{props.error}</span>}
  </S.DatePicker>
}

// 
// ─── BUTTON GROUP ───────────────────────────────────────
//
const GroupedButtons = (opt) => {
  const field = opt.field;
  const [counter, setCounter] = useState(1);

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const S = {}
  S.Label = styled.span`
    margin-right: 10px;
  `

  // 
  // ─── HANDLE INPUT INCREMENTATION OR DECREMENT ───────────────────────────────────────
  //
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
  };
  return (
    <div>
      <S.Label>{field.label}</S.Label>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button disabled={counter === 1} onClick={handleDecrement}>-</Button>
        <TextField
          size="small"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          name={field.name}
          value={counter} onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value <= 8) {
              setCounter(parseInt(e.target.value))
            }
          }}
          style={{ width: 40 }}
        />
        <Button disabled={counter === 8} onClick={handleIncrement}>+</Button>
      </ButtonGroup>
    </div>
  );
}
//
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const SearchField = (props) => {
  const field = props.field;
  const data = field.data;

  const defaultValue = getValue(field, ['default']) || field.value || ''
  const [value, setValue] = useState(field.multiple ? defaultValue.split(',').filter(v => v) || [] : defaultValue || '');

  useEffect(() => {
    if (field.default !== value) {
      setValue(field.multiple && field.default ? field.default.split(',').filter(v => v) || [] : field.default || '')
    }
  }, [field.default])

  let multipleValue

  if (value && value.length) {
    multipleValue = value.map((val) => {
      if (val.id) {
        return val.id
      } else {
        return val
      }
    })
  }

  return <>
    <Autocomplete
      id={props.name}
      size="small"
      freeSolo
      multiple={field.multiple}
      options={data}
      noOptionsText={'Aucun résultat'}
      getOptionLabel={(option) => option.label || option || ''}
      onChange={(e, inputValue) => {
        setValue(inputValue)
      }}
      defaultValue={value}
      className={props.className}
      renderInput={(params) =>
        <TextField
          {...params}
          InputLabelProps={{ shrink: true }}
          label={field.label}
          value={field.default}
          placeholder={field.placeholder}
        />
      }
    />
    {value && <input type='hidden' name={field.name} value={field.multiple && multipleValue ? multipleValue.join(',') : value.id} />}
  </>
}

// 
// ─── TIME ───────────────────────────────────────
//
export const Time = (props) => {
  const defaultValue = getValue(props, ['default']) || props.value || ''
  const [value, setValue] = useState(defaultValue)

  const handleChange = (e) => {
    setValue(e.target.value);
    if (props.handleChange) props.handleChange(e.target.value);
  };

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return <S.TimeField error={props.error ? true : false}>
    <div>
      <input
        value={value}
        onChange={handleChange}
        type="time"
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        required={props.required}
        max={props.max}
        min={props.min}
      />
      {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
    </div>
    {props.tip && <span className="tip">{props.tip}</span>}
    {props.error && <span>{props.error}</span>}
  </S.TimeField>
}

// 
// ─── TEXTAREA ───────────────────────────────────────
//
export const Textarea = (props) => {
  const defaultValue = getValue(props, ['default']) || props.value || ''
  const [value, setValue] = useState(defaultValue)

  const handleChange = (e) => {
    setValue(e.target.value);
    if (props.handleChange) {
      props.handleChange(e.target.value)
    }
  };

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  return <S.Textarea error={props.error ? true : false}>
    <div>
      <textarea
        value={value}
        onChange={handleChange}
        type={props.type}
        name={props.name}
        id={props.name}
        placeholder={props.placeholder}
        required={props.required}
      />
      {props.label && <label htmlFor={props.name}>{props.label}{props.required && !props.noStar && '*'}</label>}
    </div>
    {props.error && <span>{props.error}</span>}
  </S.Textarea>
}

//
// ─── GENERATION DU RENDER ───────────────────────────────────────
//
const Formfields = (props) => {
  const component = props.field.component || 'text';
  const width = props.field.width || '100%';

  const components = {
    text: Text,
    switch: SwitchField,
    radio: RadioField,
    select: SelectField,
    selectWithLabel: SelectFieldWithLabel,
    checkbox: CheckboxField,
    multiple: Multiple,
    file: UploadFile,
    datepicker: DatePicker,
    grouped_btn: GroupedButtons,
    search: SearchField,
    time: Time,
    textarea: Textarea
  };

  const getComponent = (name) => {
    if (!components[name]) return null;

    const Render = components[name];
    return <Render {...props.field} />;
  };

  return (
    <S.Container width={width} className={'formfield_container ' + props.classNameOuter}>
      {getComponent(component)}
    </S.Container>
  );
};

export default Formfields;

// 
// ─── INITIALISATION DES STYLES ───────────────────────────────────────
//
const S = {}
S.InputContainer = styled.div`
  flex-direction: column;
  display: ${({ hidden }) => hidden ? 'none' : 'flex'};
  width: ${({ width }) => width};

  @media screen and (max-width:780px) {
    width: 100% !important;
  }

  .input-left {
    margin-left: 5px;
  }
  .input-right {
    margin-right: 5px;
  }
`

S.Container = styled.div`
    width: ${({ width }) => width};
`

S.TextField = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}; 

  & > div {
    position: relative;
    display: flex;
    align-items: center;

    ${({ disabled }) => disabled && css`
      ::after {
        content: '';
        position: absolute;
        inset: 0;
        background: #00000033;
      }
    `}

    & label {
      z-index: 1;
      position: absolute;
      left: 10px;
      font-size: 14px;
      transition: .3s;
      color: ${({ theme }) => theme.text};
      cursor: text;
      pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
    }
    input, textarea {
      padding: 17px 10px;
      padding-bottom: 7px;
      padding-right: ${({ password }) => Boolean(password) ? '40px' : '10px'};
      font-size: 15px;
      border: none;
      width: 100%;
      box-sizing: border-box;
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.inputBg};
      font-family: ${({ theme }) => theme.primaryFont};
      
      // si le type de linput est date
      &:focus, &:valid:not(&[value=""]), &.active, &[type="date"] {
        border: none;
        outline: none;
        
        & + label {
          transform: translateY(-20px);
          font-size: 10px;
          border: 1px solid ${({ theme }) => theme.primary};
          background: ${({ theme }) => theme.card};
          color: ${({ theme }) => theme.primary};
          border-radius: 5px;
          padding: 1px 5px;
        }
      }
    }
  }
  & span {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
  
  .tip {
    color: ${({ theme }) => theme.primary};
    font-size: 11px;
    margin-top: 5px;
  }
`

S.PasswordEye = styled.div`
  position: absolute;
  right: 10px;
  display: flex;
  align-items: center;

  svg {
    width: 17px;
    height: 17px;
    fill: ${({ theme }) => theme.text};
    cursor: pointer;

    &:last-child {
      display: none;
    }
  }

  &.active {
    svg:first-child {
      display: none;
    }
    svg:last-child {
      display: block;
    }
  }
`

S.CheckboxField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: 15px;
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      -webkit-appearance: none;
      appearance: none;
      background-color: white;
      margin: 0;
      font: inherit;
      color: ${({ theme }) => theme.primary};
      min-width: 16px;
      min-height: 16px;
      border: ${({ theme, error }) => `${error ? theme.error : theme.text} 2px solid`};
      display: grid;
      transform: translateY(-0.075em);
      place-content: center;

      &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        transform: scale(0);
        transition: 120ms transform ease-in-out;
        box-shadow: ${({ theme }) => `${theme.primary} inset 1em 1em`};
        transform-origin: bottom left;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }
  .tip {
    color: ${({ theme }) => theme.primary};
    font-size: 11px;
    margin-top: 5px;
  }
  .error {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }

  @media (max-width: 800px) {
    font-size: 13px;

    label {
      font-size: 13px;
    }
  }
`

export const CheckboxFieldStyle = S.CheckboxField;

S.RadioField = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  
  // group label
  & > span:first-child {
    margin-bottom: 12px;
    font-size: 15px;
  }

  // radio group
  & > div {
    display: flex;
    gap: 15px;
    flex-direction: ${({ direction }) => direction};
    flex-wrap: ${({ direction }) => direction || direction === 'row' ? 'wrap' : 'nowrap'};
  }

  & > span:last-child {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

S.Radio = styled.label`
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  user-select: none;

  input {
    display: none;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 50%;
    transition: .3s;
  }

  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  input:checked ~ .checkmark {
    background-color: ${({ theme }) => theme.primary};
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
`

S.SelectField = styled.div`
  display: flex;
  flex-direction: column;

  & span {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }

  .tip {
    color: ${({ theme }) => theme.primary};
    font-size: 11px;
    margin-top: 5px;
  }
`

S.SelectFieldWithLabel = styled(S.SelectField)`
  position: relative;

  & label {
    z-index: 1;
    position: absolute;
    left: 10px;
    font-size: 14px;
    transition: .3s;
    color: ${({ theme }) => theme.text};
    cursor: text;
    pointer-events: ${({ disabled }) => disabled ? 'none' : 'auto'};
    transform: translateY(-8px);
    font-size: 10px;
    border: 1px solid ${({ theme }) => theme.primary};
    background: ${({ theme }) => theme.card};
    color: ${({ theme }) => theme.primary};
    border-radius: 5px;
    padding: 1px 5px;
  }

  select {
    padding: 17px 10px !important;
    padding-bottom: 7px !important;
  }
`

S.Select = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-size: 15.5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.inputBg};
  display: grid;
  grid-template-areas: "select";
  grid-area: select;
  align-items: center;
  padding-right: 10px;

  select {
    appearance: none;
    background-color: transparent;
    border: none;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;
    padding: 10px;
    padding-right: 0;
    color: ${({ theme }) => theme.text};
  }

  &::after {
    content: "";
    width: 8px;
    height: 8px;
    border-top: ${({ theme }) => `1px solid ${theme.text}`};
    border-right: ${({ theme }) => `1px solid ${theme.text}`};
    transform: rotate(135deg) translate(-1px, 1px);
    justify-self: end;
    grid-area: select;
    justify-self: end;
  }

  select::-ms-expand {
    display: none;
  }

  select, select:after {
    grid-area: select;
  }
`

S.DatePicker = styled(S.TextField)`
  svg {
    width: 16px;
    height: 16px;
    position: absolute;
    right: 10px;
    fill: ${({ theme }) => theme.text};
  }
`

S.UploadFile = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-size: 14px;
    width: 100%;

    .download {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      border-radius: 5px;
      color: ${({ theme }) => theme.textInverse};
      background: ${({ theme }) => theme.primary};
      padding: 11px 0;
      margin-top: 5px;
      width: 100%;
      cursor: pointer;
    }
  }

  p {
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.text};
    background: ${({ theme }) => theme.inputBg};
    border-radius: 5px;
    margin-top: 5px;
    padding: 5px 0;
    font-size: 14px;
  }

  input {
    display: none;
  }

  & span {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

export const UploadFileStyle = S.UploadFile;

S.TimeField = styled(S.TextField)`
  label {
    transform: translateY(-11px);
    font-size: 8px !important;
  }
  > div {
    width: 200px;

    input {
      min-height: 45px;
    }
  }
`

S.Textarea = styled(S.TextField)`
  & label {
    top: 17px;
  }
  textarea {
    min-width: 100%;
    height: 175px;
    resize: none;
  }
`