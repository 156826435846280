import styled from "styled-components";

const Stars = ({ score }) => {
  const pourcent = `${score / 5 * 100}%`;
  return <S.Container pourcent={pourcent} />
}

export default Stars;

const S = {}
S.Container = styled.div`
  width: 0;
  display: inline-block;
  font-size: 60px;
  transform: scale(0.3);
  font-family: Times; // make sure ★ appears correctly
  line-height: 1;
  
  &::before {
    content: '★★★★★';
    letter-spacing: 3px;
    background: ${({ theme, pourcent }) => `linear-gradient(90deg, ${theme.primary} ${pourcent}, ${theme.primaryLight} ${pourcent})`};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`