import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components/macro';
import Cookies from 'universal-cookie';
import ModalRefusal from '../components/ModalRefusal';
import { Button, Icon } from '../components/StyledComponents';
import { useNotification } from '../components/UseNotification';
import Api from '../contexts/Api';
import ModalBan from '../components/ModalBan';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Users = () => {
  const navigate = useNavigate();
  const { notification } = useNotification();
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const [users, setUsers] = useState({ list: {}, filtered: {} });
  const [filter, setFilter] = useState(user.role === 1 ? 0 : 3);
  const [showModalRefusal, setShowModalRefusal] = useState({
    show: false,
    user_id: null,
  });
  const [modalBan, setModalBan] = useState({
    show: false,
  });
  const currentActions = useRef(null);
  const allUsersFilter = { id: 0, label: 'Tout' }

  // ONLY SHOW DRIVER FOR PARTNERS
  let roleLabel
  if (user.role === 1) roleLabel = { 0: allUsersFilter, ...window._DATA.types && window._DATA.types.user_role } || allUsersFilter;
  else if (user.role === 4) roleLabel = { 3: window._DATA.types.user_role[3] };

  // 
  // ─── GET USERS ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsers = async () => {
      const fields = [
        'role',
        'user_id',
        'firstname',
        'name',
        'email',
        'phone',
        'status',
        'username'
      ]

      const paramsQuery = { fields };
      if (user.role === 4) {
        paramsQuery.role = 3;
        paramsQuery.postal_code = user.postal_code;
      }

      const params = {
        query: paramsQuery,
        method: 'GET',
        endpoint: '/users'
      }

      const query = await Api(params);
      if (query.data) setUsers({ list: query.data, filtered: query.data });
    }

    getUsers();
  }, [user.role, user.postal_code])

  // 
  // ─── HANDLE FILTERS ───────────────────────────────────────
  //
  const handleFilter = (i) => {
    // all users
    if (i === 0) {
      setFilter(0);
      setUsers(state => ({ ...state, filtered: users.list }));
      return users.list;
    }

    // by role
    setFilter(i);
    const obj = Object.entries(users.list);
    const filtered = obj.filter(([key, val]) => val.role === i);
    setUsers(state => ({ ...state, filtered: Object.fromEntries(filtered) }))
    return Object.fromEntries(filtered);
  }

  // 
  // ─── HANDLE SEARCH ───────────────────────────────────────
  //
  const handleSearchChange = async (e) => {
    let obj = {};

    // reset list if no search value
    if (!e.target.value || !Object.keys(users.filtered).length) {
      obj = Object.entries(handleFilter(filter));
      if (!e.target.value) return;
    }

    // filter by firstname-name-email-phone
    if (!Object.keys(obj).length) obj = Object.entries(users.filtered);
    const filtered = obj.filter(([key, val]) => {
      const nameToMatch = `${[val.firstname]} ${[val.name]} ${[val.email]} ${[val.phone]}`.toLocaleLowerCase();
      const filterToMatch = filter === 0 || val.role === filter;
      return nameToMatch.includes(e.target.value) && filterToMatch;
    })

    setUsers(state => ({ ...state, filtered: Object.fromEntries(filtered) }))
  }

  // 
  // ─── HANDLE VALIDATE USER ───────────────────────────────────────
  //
  const handleValidate = async ({ user_id, email, firstname }) => {
    const params = {
      endpoint: '/users',
      method: 'PUT',
      data: { fields: { status: 2 }, user_edit: user_id, firstname, email, send_mail: 'candidature_accepted' }
    }

    const query = await Api(params);
    notification({ variant: query.success ? 'success' : 'error', message: query.message });

    // update current obj
    const obj = { ...users };
    obj.list[user_id].status = 2;
    if (obj.filtered[user_id]) obj.filtered[user_id].status = 2;
    setUsers(obj);
  }

  // 
  // ─── HANDLE DELETE USER ───────────────────────────────────────
  //
  const handleDelete = async (opt) => {
    const { user_id, reason, firstname, email } = opt;

    // delete user in db
    const userParams = {
      endpoint: '/users',
      method: 'DELETE',
      data: { user_edit: user_id, reason, email, firstname, send_mail: 'candidature_refused' }
    }

    const userQuery = await Api(userParams);

    if (userQuery.success) {
      const message = userQuery.success ? "L'utilisateur a été supprimé" : userQuery.message
      notification({ variant: userQuery.success ? 'success' : 'error', message });

      // update users list
      const obj = { ...users };
      delete obj.list[user_id];
      if (obj.filtered[user_id]) delete obj.filtered[user_id];
      setUsers(obj);
    }
  }

  // 
  // ─── HANDLE BAN USER ───────────────────────────────────────
  //
  const handleBan = async (opt) => {
    const { user_id, email, reason, firstname } = opt;

    // disable user in db
    const userParams = {
      endpoint: '/users/disable',
      method: 'POST',
      data: { user_edit: user_id, reason, email, firstname }
    }

    const userQuery = await Api(userParams);

    if (userQuery.success) {
      const message = userQuery.success ? "L'utilisateur a été banni" : userQuery.message
      notification({ variant: userQuery.success ? 'success' : 'error', message });

      // update users list
      const obj = { ...users };
      delete obj.list[user_id];
      if (obj.filtered[user_id]) delete obj.filtered[user_id];
      setUsers(obj);

      setModalBan({ show: false });

      // TODO supprimer / annuler les missions reliées à l'utilisateur ?
    }
  }

  const handleModalRefusal = ({ user_id, firstname, email }) => {
    setShowModalRefusal({
      show: true,
      user_id,
      firstname,
      email
    });
  }

  // 
  // ─── MANAGE MODAL DISPLAY ───────────────────────────────────────
  //
  const handleModal = (display, element) => {
    const bg = document.querySelector('.background');

    if (display) {
      bg.classList.add('active');
      const moreElement = element.target.parentElement.children[0];
      if (moreElement) {
        moreElement.classList.toggle('active');
        currentActions.current = moreElement;
      }
    }
    else {
      bg.classList.remove('active');
      if (currentActions.current) currentActions.current.classList.toggle('active');
    }
  }

  // 
  // ─── DOWNLOAD USER DOCUMENTS ───────────────────────────────────────
  //
  const handleDownload = async (user_id) => {
    const params = {
      endpoint: '/users/documents',
      query: { user_get: user_id },
      method: 'GET'
    }
    const query = await Api(params);

    if (query.success) {
      // download each buffer with filename
      query.data.forEach((doc) => {
        const link = document.createElement('a');
        link.href = "data:image/png;base64," + doc.buffer;
        link.setAttribute('download', doc.fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    }
  }

  // 
  // ─── ACTION ICONS RENDER ───────────────────────────────────────
  //
  const Actions = ({ props }) => {
    const { status, role, user_id, firstname, email } = props;

    return <S.Actions>
      {status === 1 && <>
        <Icon>
          <svg onClick={() => { handleValidate({ user_id, email, firstname }); handleModal(false) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.3 100.7C449.6 106.9 449.6 117.1 443.3 123.3L171.3 395.3C165.1 401.6 154.9 401.6 148.7 395.3L4.686 251.3C-1.562 245.1-1.562 234.9 4.686 228.7C10.93 222.4 21.06 222.4 27.31 228.7L160 361.4L420.7 100.7C426.9 94.44 437.1 94.44 443.3 100.7H443.3z" /></svg>
          <span>Valider</span>
        </Icon>
        <Icon>
          <svg onClick={() => { handleModalRefusal({ user_id, email, firstname }); handleModal(false) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M315.3 411.3c-6.253 6.253-16.37 6.253-22.63 0L160 278.6l-132.7 132.7c-6.253 6.253-16.37 6.253-22.63 0c-6.253-6.253-6.253-16.37 0-22.63L137.4 256L4.69 123.3c-6.253-6.253-6.253-16.37 0-22.63c6.253-6.253 16.37-6.253 22.63 0L160 233.4l132.7-132.7c6.253-6.253 16.37-6.253 22.63 0c6.253 6.253 6.253 16.37 0 22.63L182.6 256l132.7 132.7C321.6 394.9 321.6 405.1 315.3 411.3z" /></svg>
          <span>Refuser</span>
        </Icon>
      </>}
      <Icon>
        <svg onClick={() => navigate(`/admin/utilisateurs/${user_id}`)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M386.7 22.63C411.7-2.365 452.3-2.365 477.3 22.63L489.4 34.74C514.4 59.74 514.4 100.3 489.4 125.3L269 345.6C260.6 354.1 249.9 359.1 238.2 362.7L147.6 383.6C142.2 384.8 136.6 383.2 132.7 379.3C128.8 375.4 127.2 369.8 128.4 364.4L149.3 273.8C152 262.1 157.9 251.4 166.4 242.1L386.7 22.63zM454.6 45.26C442.1 32.76 421.9 32.76 409.4 45.26L382.6 72L440 129.4L466.7 102.6C479.2 90.13 479.2 69.87 466.7 57.37L454.6 45.26zM180.5 281L165.3 346.7L230.1 331.5C236.8 330.2 242.2 327.2 246.4 322.1L417.4 152L360 94.63L189 265.6C184.8 269.8 181.8 275.2 180.5 281V281zM208 64C216.8 64 224 71.16 224 80C224 88.84 216.8 96 208 96H80C53.49 96 32 117.5 32 144V432C32 458.5 53.49 480 80 480H368C394.5 480 416 458.5 416 432V304C416 295.2 423.2 288 432 288C440.8 288 448 295.2 448 304V432C448 476.2 412.2 512 368 512H80C35.82 512 0 476.2 0 432V144C0 99.82 35.82 64 80 64H208z" /></svg>
        <span>Modifier</span>
      </Icon>
      {role === 3 && <Icon>
        <svg onClick={() => { handleDownload(user_id); handleModal(false) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M16 480c-8.8 0-16-7.2-16-16s7.2-16 16-16H368c8.8 0 16 7.2 16 16s-7.2 16-16 16H16zM203.3 379.3c-6.2 6.2-16.4 6.2-22.6 0l-128-128c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L176 329.4V224 48c0-8.8 7.2-16 16-16s16 7.2 16 16V224 329.4L308.7 228.7c6.2-6.2 16.4-6.2 22.6 0s6.2 16.4 0 22.6l-128 128z" /></svg>
        <span>Télécharger les pièces</span>
      </Icon>}
      {user.user_id !== user_id && status !== 1 && <Icon>
        <svg onClick={() => { setModalBan({ user_id, email, firstname, show: true }); handleModal(false) }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M402.7 425.3l-316-316C52.6 148.6 32 199.9 32 256c0 123.7 100.3 224 224 224c56.1 0 107.4-20.6 146.7-54.7zm22.6-22.6C459.4 363.4 480 312.1 480 256C480 132.3 379.7 32 256 32c-56.1 0-107.4 20.6-146.7 54.7l316 316zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
        <span>Bannir</span>
      </Icon>}
    </S.Actions>
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content>
      <S.Title>
        <h1>Utilisateurs</h1>
        {user.role === 1 && <Button onClick={() => navigate('/admin/utilisateurs/ajouter')}>Créer un compte</Button>}
      </S.Title>
      {Boolean(Object.keys(users).length) && <>
        <S.Header>
          <S.Filters>
            {Object.values(roleLabel).map(e =>
              <S.Filter
                key={e.id}
                onClick={() => handleFilter(e.id)}
                active={filter === e.id}
              >
                {e.label}
              </S.Filter>
            )}
          </S.Filters>
          <S.Search type='search' placeholder="Rechercher..." onChange={handleSearchChange} />
        </S.Header>
        <S.Columns>
          <span className="role">Role</span>
          <span className="name">Nom</span>
          <span className="email">Email</span>
          <span className="phone">Numéro de téléphone</span>
        </S.Columns>
        {Object.values(users.filtered).sort((a, b) => a.status - b.status).map((e, i) => <S.Card key={i}>
          <S.CardLeft>
            <span className="role"><span>{roleLabel[e.role].label}</span></span>
            <span className="name">{e.firstname} {e.name}</span>
            <span className="email">{e.email}</span>
            <span className="phone">{e.phone}</span>
          </S.CardLeft>
          <S.CardRight>
            <Actions props={{ ...e }} />
            <span onClick={(e) => handleModal(true, e)} className='more'>...</span>
          </S.CardRight>
        </S.Card>)}
      </>
      }
    </S.Content>
    <div onClick={() => handleModal(false)} className="background"></div>
    {showModalRefusal.show && <ModalRefusal modal={showModalRefusal} setModal={setShowModalRefusal} handleDelete={handleDelete} />}
    {modalBan.show && <ModalBan modal={modalBan} setModal={setModalBan} handleBan={handleBan} />}
  </S.Container>
};

export default Users;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;

  .background {
    position: absolute;
    inset: 0;
    background: #00000042;
    z-index: 10;
    display: none;

    &.active {
      display: block;
    }

    @media(min-width: 800px) {
      &.active {
        display: none;
      }
    }
  }
`

S.Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 20px;
  
  h1 {
    margin-bottom: 30px;
  }
`

S.Title = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    width: fit-content;
  }
`

S.Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  @media(max-width: 1050px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 10px;
  }
`

S.Filters = styled.div`
  display: flex;
  gap: 8px;

  @media(max-width: 800px) {
    flex-wrap: wrap;
  }
`

S.Filter = styled.span`
  padding: 6px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  background: ${({ active, theme }) => active ? theme.primary : theme.card};
  color: ${({ active, theme }) => active ? theme.textInverse : theme.text};
  transition: .2s;
  
  &:hover {
    background: ${({ theme }) => theme.primary};
    color: ${({ theme }) => theme.textInverse};
  }
`

S.Search = styled.input`
  height: 35px;
  border: none;
  background: #fff;
  width: 100%;
  border-radius: 5px;
  padding: 0 15px;
  box-sizing: border-box;
  font-size: 15px;
  font-family: ${({ theme }) => theme.primaryFont};

  &:focus {
    outline: none;
  }

  @media(min-width: 450px) {
    width: 300px;
  }
`

S.Card = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background: ${({ theme }) => theme.card};
  border-radius: 10px;
  padding: 10px 20px;
  box-sizing: border-box;
  font-weight: lighter;

  @media(max-width: 450px) {
    padding: 10px 10px;
  }
`

S.CardLeft = styled.div`
  display: flex;
  gap: 20px;
  font-size: 15px;

  & .role {
    display: flex;
    min-width: 76px;
    max-width: 76px;

    & > span {
      font-size: 12px;
      background: #00000033;
      border-radius: 5px;
      padding: 3px 7px;
    }
  }
  & > span {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  & .name {
    min-width: 160px;
    max-width: 160px;
  }
  & .email {
    min-width: 240px;
    max-width: 240px;
  }
  & .phone {
    min-width: 100px;
    max-width: 100px;
  }

  @media(max-width: 1140px) {
    .phone {
      display: none;
    }
  }

  @media(max-width: 650px) {
    .email {
      display: none;
    }
  }

  @media(max-width: 450px) {
    .name {
      min-width: unset;
      max-width: 190px;
    }
  }
`

S.CardRight = styled.div`
  display: flex;
  position: relative;

  .more {
    height: fit-content;
    font-size: 15px;
    background: #00000033;
    border-radius: 5px;
    line-height: 0.9;
    padding: 0 9px;
    height: 100%;
    cursor: pointer;

    @media(min-width: 800px) {
      display: none;
    }
  }
`

S.Actions = styled.div`
  z-index: 11;
  display: none;
  gap: 15px;
  position: absolute;
  top: -43px;
  right: 0;
  background: #00000073;
  padding: 10px;
  border-radius: 5px;

  > div {
    svg {
      fill: ${({ theme }) => theme.text};
      width: 18px;
      height: 18px;
    }
    span {
      color: ${({ theme }) => theme.text};
      padding: 3px 8px;
      font-size: 13px;
      top: calc(100% + 3px);
    }
  }

  &.active {
    display: flex;
  }

  @media(min-width: 800px) {
    z-index: unset;
    position: static;
    background: none;
    display: flex;
    padding: 0;
  }
`

S.Columns = styled(S.CardLeft)`
  padding: 0 20px;

  & .role {
    background: none;
    font-size: unset;
  }
  & .phone {
    max-width: unset;
  }

  @media(max-width: 450px) {
    padding: 0 10px;
  }
`