import styled from "styled-components";

const Loading = ({ loading, children }) => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  if (loading) return <S.Loader className="loading">
    <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
      viewBox="0 0 100 100" enableBackground="new 0 0 0 0" xmlSpace="preserve">
      <path d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
        <animateTransform
          attributeName="transform"
          attributeType="XML"
          type="rotate"
          dur="1s"
          from="0 50 50"
          to="360 50 50"
          repeatCount="indefinite" />
      </path>
    </svg>
  </S.Loader>
  return children;
};

export default Loading;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {};

S.Loader = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 50px;
    height: 50px;
    fill: ${({ theme }) => theme.primary};
  }
`