import Cookies from 'universal-cookie';

const Api = (opt) => {
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};
  let endpoint = opt.endpoint;

  // 
  // ─── CREATION DU HEADER NECESSAIRE A LA REQUETE ───────────────────────────────────────
  //
  let myHeaders = new Headers();
  if (!opt.removeHeader) {
    myHeaders.append("Content-Type", "application/json");
  }

  // 
  // ─── DEFINITION DE LA METHODE A UTILISER ───────────────────────────────────────
  //
  let method = opt.method || 'POST'

  // 
  // ─── AJOUT DES OPTIONS DANS LE HEADER ───────────────────────────────────────
  //
  let requestOptions = {
    method,
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include'
  }

  // 
  // ─── AJOUT DES DONNEES SOUS FORME DE JSON STRINGIFY ───────────────────────────────────────
  //
  if (['POST', 'PUT', 'DELETE'].includes(method)) {
    const data = opt.data || {};
    if (userData) {
      data['user_id'] = userData.user_id
    }
    requestOptions = { ...requestOptions, body: opt.removeHeader ? data : JSON.stringify(data) }
  } else {
    // Automatically add query params
    endpoint += `?user_id=${userData.user_id}`;
    if (opt.query) {
      Object.entries(opt.query).map(([key, value]) => {
        endpoint += `&${key}=${value}`
      })
    }
  }

  // 
  // ─── REQUETE A L'API ───────────────────────────────────────
  //
  return fetch(`${process.env.REACT_APP_API_URL}${endpoint}`, requestOptions)
    .then(response => response.json())
    .then(result => result)
    .catch(error => console.log('error', error));
}

export default Api;