import { useState } from 'react';
import styled from 'styled-components/macro';
import { Button } from './StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalEndMission = (props) => {
  const { setModal, modal, endMission } = props;
  const { proposal } = modal;
  const [score, setScore] = useState({ driver: [1, 0, 0, 0, 0], invited_driver: [1, 0, 0, 0, 0] });

  const closeModal = () => {
    setModal({
      show: false
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const obj = {
      driverId: proposal.driver,
      driverNote: score.driver.reduce((a, b) => a + b, 0),
      invitedDriverId: proposal.invited_driver,
      invitedDriverNote: score.invited_driver.reduce((a, b) => a + b, 0),
    }
    endMission(obj);
    closeModal();
  }

  // 
  // ─── STARS COMPONENT ───────────────────────────────────────
  //
  const Stars = ({ objKey }) => {
    // update score
    const handleScore = (max) => {
      if (score[objKey] === max) return;
      setScore(prev => ({ ...prev, [objKey]: Array.from([0, 0, 0, 0, 0], (x, i) => i <= max ? x + 1 : 0) }));
    }

    return <S.Stars>
      {score[objKey].map((e, i) =>
        e ? <svg onClick={() => handleScore(i)} key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z" /></svg> :
          <svg onClick={() => handleScore(i)} key={i} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M287.9 435.9L150.1 509.1C142.9 513.4 133.1 512.7 125.6 507.4C118.2 502.1 114.5 492.9 115.1 483.9L142.2 328.4L31.11 218.2C24.65 211.9 22.36 202.4 25.2 193.7C28.03 185.1 35.5 178.8 44.49 177.5L197.7 154.8L266.3 13.52C270.4 5.249 278.7 0 287.9 0C297.1 0 305.5 5.25 309.5 13.52L378.1 154.8L531.4 177.5C540.4 178.8 547.8 185.1 550.7 193.7C553.5 202.4 551.2 211.9 544.8 218.2L433.6 328.4L459.9 483.9C461.4 492.9 457.7 502.1 450.2 507.4C442.8 512.7 432.1 513.4 424.9 509.1L287.9 435.9zM226.5 168.8C221.9 178.3 212.9 184.9 202.4 186.5L64.99 206.8L164.8 305.6C172.1 312.9 175.5 323.4 173.8 333.7L150.2 473.2L272.8 407.7C282.3 402.6 293.6 402.6 303 407.7L425.6 473.2L402.1 333.7C400.3 323.4 403.7 312.9 411.1 305.6L510.9 206.8L373.4 186.5C362.1 184.9 353.1 178.3 349.3 168.8L287.9 42.32L226.5 168.8z" /></svg>
      )}
    </S.Stars>
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content id="postulation-form">
      <p>Noter {proposal.invited_driver ? 'les chauffeurs' : 'le chauffeur'}</p>
      <S.StarsContainer>
        <span>{proposal.driver_firstname} {proposal.driver_name}</span>
        <Stars objKey='driver' />
      </S.StarsContainer>
      {proposal.invited_driver && <S.StarsContainer>
        <span>{proposal.invited_driver_firstname} {proposal.invited_driver_name}</span>
        <Stars objKey="invited_driver" />
      </S.StarsContainer>}
      <S.Buttons>
        <Button type="button" onClick={closeModal} outline={true}>Annuler</Button>
        <Button onClick={handleSubmit}>Valider</Button>
      </S.Buttons>
    </S.Content>
    <S.Background onClick={closeModal}></S.Background>
  </S.Container>
};

export default ModalEndMission;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
`

S.Content = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 23%);
  padding: 20px;
  max-width: 400px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;

  p {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 600;
  }

  input:not([type="submit"]) {
    border: 1.5px solid #8a8a8a !important;
    border-radius: 5px;
  }
`

S.Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;

  button {
    width: 160px;
  }
`

S.Background = styled.div`
  inset: 0;
  position: fixed;
  background: #000000a3;
  z-index: 999;
`

S.StarsContainer = styled.div`
  font-size: 17px;
  display: flex;
  gap: 10px;
  flex-direction: column;
`

S.Stars = styled.div`
  display: flex;
  margin-bottom: 25px;

  & svg {
    width: 25px;
    height: 25px;
    fill: ${({ theme }) => theme.primary};
  }
`