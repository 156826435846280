import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import Cookies from 'universal-cookie';
import Api from '../contexts/Api';
import noMissions from '../assets/no-missions.png';
import driverSleep from '../assets/driver-sleep.png';
import Loading from '../components/generic/Loading';
import ModalExcel from '../components/ModalExcel';
import { Button } from '../components/StyledComponents';
import { formatDate, formatTime, isInReunion } from '../contexts/Utils';
import { Link } from 'react-router-dom';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TravelsAdmin = (props) => {
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const [missions, setMissions] = useState({});
  const [team, setTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModalExcel, setShowModalExcel] = useState(false);
  const [proposals, setProposals] = useState({});
  const statusTypes = window._DATA.types.mission_status;
  // const isDisabled = user.disabled === 1;
  // const mustHaveSubscription = user.driver_type === 2 || (user.driver_type === 1 && user.limit_amount_reached === 1);
  // const hasSubscribed = user.stripe_subscription_enabled === 1;
  // const canPostulate = props.canPostulate && !isDisabled && user.status === 2 && user.active_role === 3 && Boolean(user.stripe_charges_enabled) && (mustHaveSubscription ? hasSubscribed : true) ? true : false;

  let title = "Accueil";
  switch (window.location.pathname) {
    case '/mes-missions':
      title = 'Mes missions';
      break;
    case '/trajets':
      title = 'Trajets';
      break;
    case '/nouvelles-courses':
      title = 'Nouvelles courses';
      break;
    case '/':
      title = 'Accueil';
      break;
    default:
      break;
  }

  const statusColors = {
    1: { color: '#FFFFFF', background: '#b5a9a5' },
    2: { color: '#f6feff', background: '#75A6E6' },
    3: { color: '#1e6b26', background: '#93e0a2' },
    4: { color: '#6b1e1e', background: '#e09393' },
  }

  // 
  // ─── GET DATA FROM DB ───────────────────────────────────────
  //
  useEffect(() => {
    const getMissions = async () => {
      const query = {}
      if (props.mission_user_get) query.user_get = props.mission_user_get;
      if (props.canPostulate) {
        if (user.role === 3) query.not_current_user = true;
        query.role = user.active_role;
        query.postal_code = user.postal_code;
        query.driver_type = user.driver_type;
      }
      if (props.status) query.status = props.status;
      if (user.role === 4) {
        query.postal_code = user.postal_code
        query.role = 4
      }

      query.admin = true;

      const params = {
        query,
        endpoint: '/missions',
        method: 'GET'
      }

      const missionsQuery = await Api(params);

      // update state for admins page
      if ([1, 4].includes(user.role)) {
        const dataArray = Object.values(missionsQuery.success ? missionsQuery.data : {});

        // Trier le tableau en fonction de la propriété "status"
        dataArray.sort((a, b) => {
          // Si les statuts sont égaux, comparez par date
          if (a.status === b.status) {
            return a.date - b.date;
          }
          // Sinon, comparez par statut
          return a.status - b.status;
        });

        // Recréer un objet à partir du tableau trié
        const sortedObject = {};
        dataArray.forEach(item => {
          sortedObject[item.mission_id] = item;
        });

        setMissions(sortedObject);

        setLoading(false);
      }
      else return missionsQuery.success ? missionsQuery.data : {};
    }

    const getClientMissions = async () => {
      // GET PROPOSALS
      const proposalsParams = {
        query: { driver_approval: 1, teammate_approval: 1, mission_user_id: user.user_id, soloForClient: true },
        endpoint: '/proposals',
        method: 'GET',
      }

      Promise.all([getMissions(), Api(proposalsParams)]).then(([missions, proposals]) => {
        if (proposals.success) {
          // sort proposals by mission
          const sortedProposals = Object.values(proposals.data).reduce((final, current) => {
            if (final.hasOwnProperty(current.mission_id)) final[current.mission_id].push(current);
            else final[current.mission_id] = [current];
            return final;
          }, {});

          setProposals(sortedProposals);
        }

        setMissions(missions);
        setLoading(false);
      });
    }

    // 
    // ─── MES MISSIONS CHAUFFEUR ───────────────────────────────────────
    //
    const getDriverTravelsData = async () => {
      // GET PROPOSALS
      let query = { driver_id: user.user_id, invited_driver_id: JSON.stringify([user.user_id]), withMissions: true, subtype: true }
      if (props.status) query.status = props.status;

      const params = {
        endpoint: '/proposals',
        method: 'GET',
        query
      }

      const proposalsQuery = await Api(params);

      if (proposalsQuery.success) {
        let missionsObj = {}, proposalsObj = {};

        // create mission & proposals object
        Object.entries(proposalsQuery.data).forEach(([status, mission]) => {
          missionsObj[status] = {};

          Object.entries(mission).forEach(([mission_id, proposals]) => {
            missionsObj[status][mission_id] = proposals[0];
            proposalsObj[mission_id] = proposals;
          });
        });

        setMissions(missionsObj);
        setProposals(proposalsObj);
      }

      setLoading(false);
    }

    // 
    // ─── NOUVELLES COURSES ───────────────────────────────────────
    //
    const getNewTravelsData = async () => {
      // GET PROPOSALS
      const proposalsParams = {
        query: { driver_approval: 0, driver_id: user.user_id, invited_driver_id: JSON.stringify([user.user_id]), subtype: true },
        endpoint: '/proposals',
        method: 'GET',
      }

      // GET TEAM
      const teamParams = {
        query: { user_get: user.user_id, accepted: 1 },
        endpoint: '/teams',
        method: 'GET'
      }

      const promiseArray = [getMissions(), Api(proposalsParams)];
      if (user.driver_type === 1) promiseArray.push(Api(teamParams));

      Promise.all(promiseArray).then(([missions, proposals, team]) => {
        // format for select
        if (proposals.success) {
          const arr = Object.values(proposals.data).reduce((final, current) => {
            if (!final.hasOwnProperty(current.mission_id)) final[current.mission_id] = [];
            final[current.mission_id].push({ ...current, id: current.driver, label: current.driver })
            return final;
          }, {})
          setProposals(arr);
        }

        // format for select
        if (team && team.success) {
          const arr = Object.values(team.data).reduce((final, current) => {
            final[current.team_id] = { id: current.team_id, label: user.email === current.driver ? current.invited_driver : current.driver }
            return final;
          }, {})
          setTeam(arr);
        };

        setMissions(missions);
        setLoading(false);
      });
    }

    if (props.mission_user_get) getClientMissions();
    if (props.proposal_user_get) getDriverTravelsData();
    if (props.canPostulate) getNewTravelsData();
    if ([1, 4].includes(user.role)) getMissions();
  }, [user.active_role, user.user_id, user.email, user.role, props.status, props.mission_user_get, props.proposal_user_get, props.canPostulate, user.driver_type, user.postal_code])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  if (!missions || Object.keys(missions).length === 0) return <S.Container>
    {title && <h1>{title}</h1>}
    <S.NoMissions>
      <Loading loading={loading}>
        <img src={user.active_role === 2 ? driverSleep : noMissions} alt='Pas de missions' />
        <p>Aucune mission !</p>
      </Loading>
    </S.NoMissions>
  </S.Container>
  return <div>
    {showModalExcel && <ModalExcel onClose={() => setShowModalExcel(false)} />}
    <S.PageTitle>
      <h1>Liste des missions</h1>
      <S.Button onClick={() => setShowModalExcel(true)}>Exporter</S.Button>
    </S.PageTitle>
    <S.MissionsContainer>
      {Object.values(missions).map((mission) => {
        const withVehicle = Boolean(mission.vehicle) && Object.keys(mission.vehicle).length > 0;
        return <S.MissionCard to={`/trajets/${mission.mission_id}`}>
          <S.MissionTitle>
            <h3 className='cities'>{mission.origin_city} <span>→</span> {mission.destination_city}</h3>
            <S.Status style={{ color: statusColors[mission.status].color, background: statusColors[mission.status].background }}>{statusTypes[mission.status].label}</S.Status>
          </S.MissionTitle>


          <S.MissionContent>
            <span className="date">
              Date : {mission.date_type == 1 ? 'Dès que possible' : `${formatDate({ display: 'date', time: mission.date })} à ${formatDate({ display: 'timer', time: isInReunion(mission.origin_cp) ? new Date(new Date(mission.date).setHours(new Date(mission.date).getHours() - (typeof mission.date == 'number' ? 2 : 0))) : mission.date })}`}
            </span>
            <span>Distance : {mission.distance}km </span>
            <span>Durée :  {formatTime({ duration: mission.duration, display: 'hour' })}</span>
            <span>Prix : {mission.price}€</span>
            <S.Options>
              <span>
                <svg className='car' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M171.3 96H224v96H111.3l30.4-75.9C146.5 104 158.2 96 171.3 96zM272 192V96h81.2c9.7 0 18.9 4.4 25 12l67.2 84H272zm256.2 1L428.2 68c-18.2-22.8-45.8-36-75-36H171.3c-39.3 0-74.6 23.9-89.1 60.3L40.6 196.4C16.8 205.8 0 228.9 0 256V368c0 17.7 14.3 32 32 32H65.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H385.3c7.6 45.4 47.1 80 94.7 80s87.1-34.6 94.7-80H608c17.7 0 32-14.3 32-32V320c0-65.2-48.8-119-111.8-127zM434.7 368a48 48 0 1 1 90.5 32 48 48 0 1 1 -90.5-32zM160 336a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" /></svg>
                {Boolean(mission.recovery) ? <svg className='loop' width="188" height="227" viewBox="0 0 188 227" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M71.3225 221.679C78.6663 228.774 90.5925 228.774 97.9363 221.679C105.28 214.585 105.28 203.064 97.9363 195.97L83.0138 181.611H94C145.935 181.611 188 140.976 188 90.8057C188 40.6355 145.935 0 94 0H18.8C8.40124 0 0 8.11576 0 18.1611C0 28.2065 8.40124 36.3223 18.8 36.3223H94C125.138 36.3223 150.4 60.7263 150.4 90.8057C150.4 120.885 125.138 145.289 94 145.289H83.0138L97.9363 130.874C105.28 123.779 105.28 112.259 97.9363 105.164C90.5925 98.0701 78.6663 98.0701 71.3225 105.164L24.3225 150.567C16.9787 157.661 16.9787 169.182 24.3225 176.277L71.3225 221.679Z" /></svg>
                  : <svg className='car-two' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M502.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l370.7 0-73.4 73.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l128-128z" /></svg>
                }
                {Boolean(mission.recovery) ? 'Aller-retour' : 'Aller simple'}
              </span>
              {withVehicle ? <span>
                <svg className='car-two' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M135.2 117.4L109.1 192H402.9l-26.1-74.6C372.3 104.6 360.2 96 346.6 96H165.4c-13.6 0-25.7 8.6-30.2 21.4zM39.6 196.8L74.8 96.3C88.3 57.8 124.6 32 165.4 32H346.6c40.8 0 77.1 25.8 90.6 64.3l35.2 100.5c23.2 9.6 39.6 32.5 39.6 59.2V400v48c0 17.7-14.3 32-32 32H448c-17.7 0-32-14.3-32-32V400H96v48c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V400 256c0-26.7 16.4-49.6 39.6-59.2zM128 288a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm288 32a32 32 0 1 0 0-64 32 32 0 1 0 0 64z" /></svg>
                Avec véhicule
              </span>
                : <span>Sans véhicule</span>}
            </S.Options>
          </S.MissionContent>
        </S.MissionCard>
      })}
    </S.MissionsContainer>
  </div>
};

export default TravelsAdmin;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  
  h1 {
    margin-bottom: 20px;
  }

  @media(min-width: 800px) {
    margin-top: 0;
  }
`

S.Category = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 30px;
  margin-top: 20px;

  :last-of-type {
    margin-bottom: 0;
  }

  @media(min-width: 800px) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    grid-gap: 20px;
    background: none;
    border-radius: unset;
  }
`

S.EmptyDiv = styled.div`
  display: none;

  @media(min-width: 800px) {
    display: block;
  }
`

S.NoMissions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 30px;
  font-size: 17px;

  img {
    width: 170px;
  }
`

S.Button = styled(Button)`
  width: fit-content;
  height: fit-content;
`

S.MissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
  margin-top: 40px;
`

S.MissionCard = styled(Link)`
  background: ${({ theme }) => theme.card};
  padding:15px;
  display: flex;
  flex-direction: column;
  gap:10px;
`

S.MissionTitle = styled.div`
  display: flex ;
  align-items: center;
  justify-content:space-between;
  gap:20px;
  span{    color: ${({ theme }) => theme.primary};}
`

S.MissionContent = styled.div`
  display: flex ;
  flex-wrap: wrap;
  align-items: center;
  justify-content:space-between;
  gap:20px;
`

S.Status = styled.span`
  padding:4px 10px;
`

S.Options = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap:20px;
  row-gap: 10px;
  flex-wrap: wrap;
  margin-top: 8px;

  svg {
    fill: ${({ theme }) => theme.primary};
  }
  .car {
    width: 19px;
    margin-right: 4px;
  }
  .loop {
    width: 12px;
    height: auto;
    margin-right: 10px;
  }
  .car-two {
    width: 17px;
    margin-right: 10px;
  }
  span {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primary};
  }
`

S.PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`