import React, { useEffect, useRef, useState } from 'react';
import { getValue } from '../../contexts/Utils';
import styled from 'styled-components';

//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const GeoSearch = (props) => {
  const [geo, setGeo] = useState([]);
  const [search, setSearch] = useState('');
  const [focusActive, setFocusActive] = useState(false);
  const [geoApi, setGeoApi] = useState({ address: '', cp: '', city: '' });

  const name = useRef(props.name);

  useEffect(() => {
    const defaultValue = props.default || '';
    const newName = Boolean(name.current !== props.name);
    const newValue = Boolean(search !== defaultValue[props.name] && defaultValue[`${props.name}_address`]);

    if (newName) name.current = props.name;
    if (newName || newValue) {
      setGeo([]);
      setSearch(defaultValue[props.name]);
      setGeoApi({ ...defaultValue });
    }
  }, [props.name, props.default])

  useEffect(() => {
    const getData = async () => {
      try {
        const query = await fetch(`https://api-adresse.data.gouv.fr/search/?q=${search}`);
        const result = await query.json();

        if (getValue(result, ['features']).length) {
          const geoArray = [];
          result.features.forEach((value) => {
            const values = value.properties;

            let geoValues = {
              label: values.label,
              city: values.city,
              cp: values.postcode,
              address: values.type === 'municipality' ? '' : values.name,
            };

            geoArray.push(geoValues);
          });
          setGeo([...geoArray]);
        }
      }
      catch (err) { }
    }

    const timer = setTimeout(
      () => search && search.length > 2 && getData(),
      200
    );
    return () => clearTimeout(timer);
  }, [search]);

  const handleChange = (e) => {
    setSearch(e.target.value);
    setGeoApi({ address: '', cp: '', city: '' });
  }

  // 
  // ─── HANDLE RESULT CLICK ───────────────────────────────────────
  //
  const handleResultClick = (res) => {
    setSearch(res.label);
    setGeoApi({ ...res });
  }

  // 
  // ─── HANDLE SEARCH FOCUS ───────────────────────────────────────
  //
  const handleFocus = (val) => {
    if (!val) {
      setTimeout(() => {
        setFocusActive(val);
      }, 150);
      return
    }

    setFocusActive(val);
  }

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    geo && (
      <>
        <S.Search>
          <S.TextField error={props.error ? true : false} icon={Boolean(props.icon)} iconWidth={props.iconWidth}>
            <div>
              {props.icon && props.icon}
              <input
                value={search || ''}
                onChange={handleChange}
                type="text"
                name={props.name}
                id={props.name}
                placeholder={props.placeholder}
                onFocus={() => handleFocus(true)}
                onBlur={() => handleFocus(false)}
                required={props.required}
                autoComplete="off"
              />
              {props.label && <label htmlFor={props.name}>{props.label}</label>}
            </div>
            {props.error && <span>{props.error}</span>}
          </S.TextField>
          {focusActive && <S.Results>
            {geo.map(e => <div key={e.label}><S.Result onClick={() => handleResultClick(e)}>
              {e.label}
            </S.Result></div>)}
          </S.Results>}
        </S.Search>
        <input
          type="hidden"
          name={`${props.name ? `${props.name}_` : ''}address`}
          value={geoApi.address || geoApi[`${props.name}_address`] || ''}
          required={props.required}
        />
        <input
          type="hidden"
          name={`${props.name ? `${props.name}_` : ''}cp`}
          value={geoApi.cp || geoApi[`${props.name}_cp`] || ''}
          required={props.required}
        />
        <input
          type="hidden"
          name={`${props.name ? `${props.name}_` : ''}city`}
          value={geoApi.city || geoApi[`${props.name}_city`] || ''}
          required={props.required}
        />
      </>
    )
  );
};

export default GeoSearch;

const S = {}
S.TextField = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  & svg {
    width: ${({ iconWidth }) => iconWidth ? `${iconWidth}px` : '20px'};
    height: 20px;
    fill: ${({ theme }) => theme.text};
    position: absolute;
    left: 8px;
  }
  & > div {
    position: relative;
    display: flex;
    align-items: center;

    & label {
      cursor: text;
      position: absolute;
      left: 10px;
      left: ${({ icon }) => icon ? '38px' : '10px'};
      font-size: 14px;
      transition: .3s;
      color: ${({ theme }) => theme.text};
    }
    & input {
      padding: 17px 10px;
      padding-bottom: 7px;
      padding-right: ${({ password }) => Boolean(password) ? '40px' : '10px'};
      padding-left: ${({ icon }) => icon ? '38px' : '17px'};
      font-size: 15px;
      border: none;
      width: 100%;
      box-sizing: border-box;
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.inputBg};
      font-family: ${({ theme }) => theme.primaryFont};
      
      &:focus, :not(&[value=""]) {
        border: none;
        
        & + label {
          transform: translateY(-20px);
          font-size: 10px;
          border: 1px solid ${({ theme }) => theme.primary};
          background: ${({ theme }) => theme.card};
          color: ${({ theme }) => theme.primary};
          border-radius: 5px;
          padding: 1px 5px;
        }
      }
    }
  }
  & span {
    margin-top: 5px;
    font-size: 13px;
    color: ${({ theme, error }) => error ? theme.error : 'silver'};
  }
`

S.Search = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  position: relative;
`

S.Results = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50px;
  background: ${({ theme }) => theme.text};
  width: 100%;
  z-index: 100;
  background: ${({ theme }) => theme.inputBg};
  max-height: 230px;
  overflow-y: auto;

  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
  }
`

S.Result = styled.div`
  padding: 5px 10px;
  transition: .3s;
  cursor: pointer;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;

  &:hover {
    background: #c6c7d2;
  }
  &:first-child {
    padding-top: 10px;
  }
  &:last-child {
    padding-bottom: 10px;
  }
`