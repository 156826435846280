export const globalTheme = {
  blue: '#404FE6',
  green: '#43FFCF',
  black: '#1A1D33',
  appPadding: '0 20px',
  appMediumPadding: '0 70px',
  appDesktopPadding: '0 140px',
  titleFont: "'Quicksand', sans-serif",
  primaryFont: "'Montserrat', sans-serif"
}

export const lightTheme = {
  error: '#d31717',
  primary: '#404FE6',
  primaryDark: '#3340bf',
  primaryLight: '#b5b8d5',
  background: '#F3F6FF',
  backgroundDarker: '#dfe4f5',
  card: '#FFFFFF',
  text: '#1A1D33',
  textInverse: '#FFFFFF',
  inputBg: '#E7E8F4',
  navbarIcons: '#D1D2D6',
  navbarLogo: '#F3F6FF',
  radioDesktop: '#f4f4f4',
  linearPay: 'linear-gradient(180deg,#ffffff00 0%,#b4b4b4 100%)'
}

export const darkTheme = {
  error: '#ff5353',
  primary: '#43FFCF',
  primaryLight: '#89cebc',
  background: '#222549',
  backgroundDarker: '#222549',
  card: '#3A3E71',
  text: '#FFFFFF',
  textInverse: '#1A1D33',
  inputBg: '#3A3E71',
  navbarIcons: '#FFFFFF',
  navbarLogo: '#FFFFFF',
  radioDesktop: '#2e3159',
  linearPay: 'linear-gradient(180deg,#ffffff00 0%,#15162d 100%)'
}