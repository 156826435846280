import { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Cookies from 'universal-cookie';
import Api from '../contexts/Api';
import { Context } from '../App';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ViewContainer = ({ children, title }) => {
  const { setNotif } = useContext(Context);
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── GET NOTIFICATIONS ───────────────────────────────────────
  //
  useEffect(() => {
    const getNotif = async () => {
      const params = {
        query: { invited_id: user.user_id, accepted: 0 },
        endpoint: '/teams',
        method: 'GET'
      }

      const query = await Api(params);
      if (query.success) setNotif(prev => ({ ...prev, profile: true }));
    }

    if (user.user_id && user.active_role === 3 && user.driver_type === 1) getNotif();
  }, [user.user_id, setNotif, user.driver_type, user.active_role])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <Helmet title={title} />
    {children}
  </>
};

export default ViewContainer;