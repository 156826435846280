import { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import Cookies from 'universal-cookie';
import Summary from '../components/Summary';
import Api from '../contexts/Api';
import { Button, Icon } from '../components/StyledComponents';
import { useNotification } from '../components/UseNotification';
import Stars from '../components/generic/Stars';
import Loading from '../components/generic/Loading';
import { formatDate, formatTime, isInReunion } from '../contexts/Utils';
import PostulationWarning from '../components/PostulationWarning';
import LoadingButton from '../components/generic/LoadingButton';
import ModalMissionDelete from '../components/ModalMissionDelete';
import ModalDispute from '../components/ModalDispute';
import ModalEndMission from '../components/ModalEndMission';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TravelDetail = () => {
  const { notification } = useNotification();
  const navigate = useNavigate();
  const cookies = useMemo(() => new Cookies(), []);
  const user = useMemo(() => cookies.get(process.env.REACT_APP_USER_COOKIE), [cookies]);
  const { uuid } = useParams();
  const [mission, setMission] = useState({});
  const [loading, setLoading] = useState(true);
  const [captureLoading, setCaptureLoading] = useState(false);
  const [proposals, setProposals] = useState({});
  const [reviews, setReviews] = useState({});
  const [endMissionModal, setEndMssionModal] = useState({ show: false });
  const [deleteModal, setDeleteModal] = useState({ show: false });
  const [disputeModal, setDisputeModal] = useState({ show: false });
  const [allProposals, setAllProposals] = useState([])

  const withVehicle = Boolean(mission.vehicle) && Object.keys(mission.vehicle).length > 0;

  // 
  // ─── CHECKS TO DELETE MISSION ───────────────────────────────────────
  //
  // CHECK IF DATE IS ONE HOUR BEFORE
  // Calculer la différence en millisecondes entre les deux dates
  const date = new Date(mission.date);
  const difference = Date.now() - date;
  // Convertir la différence en minutes
  const minutesDiff = Math.abs(difference / (1000 * 60));
  // Vérifier si la différence est inférieure à 60 minutes
  const missionIsAfterNow = Date.now() < date;
  const isMoreThanOneHour = missionIsAfterNow && minutesDiff > 60;

  // CHECK IF USER IS ADMIN OR MISSION CREATOR
  const isAdminOrCreator = user.active_role === 1 || (user.active_role === 2 && mission.email === user.email);

  // 
  // ─── CHECK INVITES/POSTULATIONS (DRIVER) ───────────────────────────────────────
  //
  let hasTeamInvite = false, hasTeammatePostulations = false;
  const teamInviteCond = (proposal) => proposal.invited_driver === user.email && proposal.teammate_approval === 0;
  const teammatePostulationsCond = (proposal) => proposal.driver === user.email && proposal.invited_driver !== null && proposal.driver_approval === 0;
  if (mission.status === 1 && user.active_role === 3 && Object.keys(proposals).length) {
    hasTeamInvite = Object.values(proposals).some(teamInviteCond);
    hasTeammatePostulations = Object.values(proposals).some(teammatePostulationsCond);
  }

  // 
  // ─── CHECK TEAM POSTULATION (CLIENT) ───────────────────────────────────────
  //
  let hasTeamPostulation = false;
  if (mission.status === 1 && user.active_role === 2 && Object.keys(proposals).length) hasTeamPostulation = true;

  // 
  // ─── GET DATA ───────────────────────────────────────
  //
  useEffect(() => {
    const getData = async () => {
      // 
      // ─── GET MISSIONS ───────────────────────────────────────
      //
      const missionsParams = {
        query: { mission_id: uuid, detail: true },
        endpoint: '/missions',
        method: 'GET'
      }

      // 
      // ─── GET PROPOSALS ───────────────────────────────────────
      //
      let query = {};
      if (user.active_role === 1 || user.active_role === 4) query = { mission_id: uuid, withUsersInfo: true, clientInfo: true };
      if (user.active_role === 3) query = { driver_id: user.user_id, invited_driver_id: user.user_id, subtype: true, withUsersInfo: true, andMissionId: uuid, clientInfo: true };
      if (user.active_role === 2) query = { driver_approval: 1, teammate_approval: 1, mission_id: uuid, withUsersInfo: true, soloForClient: true }

      const proposalsParams = {
        query,
        endpoint: '/proposals',
        method: 'GET'
      }

      Promise.all([Api(missionsParams), Api(proposalsParams)]).then(([missions, proposals]) => {
        if (missions.success) setMission(missions.data);
        if (proposals.success) {

          // Filter element by mission_id
          let removeOthers = {};
          Object.entries(proposals.data).map(([key, value]) => {
            if (value.mission_id === uuid) {
              removeOthers[key] = value;
            }
          })

          setProposals(removeOthers);
          setAllProposals(proposals.allProposals)

          if (Object.keys(proposals.reviews).length) {
            setReviews(proposals.reviews);
          }
        }
        setLoading(false);
      });
    }

    loading && getData();
  }, [uuid, user, loading])

  // 
  // ─── GET OTHER DRIVER EMAIL ───────────────────────────────────────
  //
  const getTeammateEmail = (proposal) => {
    return proposal.driver === user.email ? proposal.invited_driver : proposal.driver;
  }

  // 
  // ─── DRIVER ACCEPT PROPOSAL ───────────────────────────────────────
  //
  const acceptProposal = async (proposal_id) => {
    let data = { proposal_id, fields: { driver_approval: 1, teammate_approval: 1 } };
    let currentPrice, currentDate;

    // get choosed price from initial proposal
    const propWithPrice = Object.values(proposals).find(proposal => proposal.driver_approval === 0 && proposal.teammate_approval === 0 && proposal.driver === proposals[proposal_id].driver);

    if (propWithPrice) {
      data.fields.price = propWithPrice.price;
      data.fields.price_fee = propWithPrice.price_fee;
      data.fields.date = new Date(propWithPrice.date).toISOString().slice(0, 16);
      data.fields.external_payment = propWithPrice.external_payment;

      currentPrice = propWithPrice.price;
      currentDate = new Date(new Date(propWithPrice.date).setHours(new Date(propWithPrice.date).getHours() + (isInReunion(mission.origin_cp) ? 4 : 2))).toISOString().slice(0, 16);
    }
    else {
      currentPrice = proposals[proposal_id].price;
      currentDate = new Date(new Date(proposals[proposal_id].date).setHours(new Date(proposals[proposal_id].date).getHours() + (isInReunion(mission.origin_cp) ? 4 : 2))).toISOString().slice(0, 16);
    }

    // SEND MAIL
    data.send_mail_data = {
      partnerEmail: proposals[proposal_id].driver_approval === 0 ? proposals[proposal_id].invited_driver : proposals[proposal_id].driver,
      partnerFirstname: proposals[proposal_id].driver_approval === 0 ? proposals[proposal_id].driver_firstname : proposals[proposal_id].invited_driver_firstname,
      missionTitle: `${mission.origin_city} - ${mission.destination_city}`,
      mission_id: mission.mission_id,
      driver_id: proposals[proposal_id].driver_user_id,
      invited_driver_id: proposals[proposal_id].invited_driver_user_id,
      duration: formatTime({ duration: mission.duration, display: 'hour' }),
      date: mission.date_type === 1 ? 'Dès que possible' : formatDate({ display: 'date', time: mission.date }) + ' à ' + formatDate({ display: 'timer', time: isInReunion(mission.origin_cp) ? new Date(mission.date).setHours(new Date(mission.date).getHours() - 2) : mission.date }),
      origin: `${mission.origin_address} ${mission.origin_cp} ${mission.origin_city}`,
      destination: `${mission.destination_address} ${mission.destination_cp} ${mission.destination_city}`,
      additional: mission.additional_city ? `${mission.additional_address} ${mission.additional_cp} ${mission.additional_city}` : 'Aucune',
      priceDivided: (currentPrice / 2).toFixed(2),
    }
    data.send_mail = 'accept_proposal';

    const acceptParams = {
      data,
      endpoint: '/proposals',
      method: 'PUT'
    }

    await Promise.all([Api(acceptParams)]).then(values => {
      const acceptQuery = values[0];
      notification({ variant: acceptQuery.success ? 'success' : 'error', message: acceptQuery.message });

      // update state
      if (acceptQuery.success) {
        setProposals(prev => ({ ...prev, [proposal_id]: { ...prev[proposal_id], driver_approval: 1, teammate_approval: 1, price: currentPrice, date: currentDate } }));
      }
    });
  }

  // 
  // ─── CLIENT ACCEPT TEAM PROPOSAL / DELETE ALL OTHERS ───────────────────────────────────────
  //
  const acceptTeamProposal = async (proposal_id) => {
    const { price, price_fee } = proposals[proposal_id];
    const date = new Date(proposals[proposal_id].date).toISOString().slice(0, 16);
    const isAlone = !withVehicle || proposals[proposal_id].solo === 1;
    const external_payment = proposals[proposal_id].external_payment;

    // pass missions to status 2
    const missionParams = {
      data: { fields: { status: 2, price, price_fee, date, date_type: 2, external_payment }, mission_id: uuid },
      endpoint: '/missions',
      method: 'PUT'
    }

    // delete others proposals
    const deleteParams = {
      data: {
        proposal_id,
        notId: true,
        and_mission_id: uuid,
        send_mail_data: {
          mission_id: uuid,
          missionTitle: `${mission.origin_city} - ${mission.destination_city}`,
          clientFirstname: user.firstname,
          driverFirstname: proposals[proposal_id].driver_firstname,
          driverEmail: proposals[proposal_id].driver,
          partnerFirstname: proposals[proposal_id].invited_driver_firstname,
          partnerEmail: proposals[proposal_id].invited_driver,
          date: formatDate({ display: 'date', time: date }) + ' à ' + formatDate({ display: 'timer', time: date }),
          origin: `${mission.origin_address} ${mission.origin_cp} ${mission.origin_city}`,
          destination: `${mission.destination_address} ${mission.destination_cp} ${mission.destination_city}`,
          additional: mission.additional_city ? `${mission.additional_address} ${mission.additional_cp} ${mission.additional_city}` : 'Aucune',
          duration: formatTime({ duration: mission.duration, display: 'hour' }),
          price: price.toFixed(2),
          priceDivided: (price / 2).toFixed(2),
        },
        fields: { status: 1 },
        send_mail: isAlone ? 'mission_accepted_solo' : 'mission_accepted'
      },
      endpoint: '/proposals',
      method: 'PUT'
    }

    await Promise.all([Api(missionParams), Api(deleteParams)]).then(values => {
      const missionQuery = values[0];
      const message = missionQuery.success ? `Le ${isAlone ? 'chauffeur' : 'binôme'} a été choisi` : missionQuery.message;
      notification({ variant: missionQuery.success ? 'success' : 'error', message });

      // update state
      if (missionQuery.success) {
        setMission(prev => ({ ...prev, status: 2, price, price_fee, date_type: 2, date, external_payment }));
        setProposals(prev => ({ ...{ [proposal_id]: { ...prev[proposal_id] } } }));
      }
    });
  }

  // 
  // ─── DELETE SPECIFIC PROPOSAL ───────────────────────────────────────
  //
  const deleteProposal = async ({ proposal_id, cancel = false, driverRefuse = false, invitedDriverRefuse = false }) => {
    const data = { proposal_id };

    // un driver refuse un invited driver
    if (driverRefuse) {
      data.mission_id = mission.mission_id;
      data.mission_title = `${mission.origin_city} - ${mission.destination_city}`;
      data.send_mail = 'partner_canceled_mission';
      data.driver_firstname = proposals[proposal_id].invited_driver_firstname;
      data.partner_firstname = user.firstname;
      data.email = proposals[proposal_id].invited_driver;
    }
    // un invited driver refuse un driver
    else if (invitedDriverRefuse) {
      data.mission_id = mission.mission_id;
      data.mission_title = `${mission.origin_city} - ${mission.destination_city}`;
      data.send_mail = 'partner_canceled_mission';
      data.driver_firstname = proposals[proposal_id].driver_firstname;
      data.partner_firstname = user.firstname;
      data.email = proposals[proposal_id].driver;
    }

    const params = {
      data,
      endpoint: '/proposals',
      method: 'DELETE'
    }
    const query = await Api(params);

    if (!cancel || !query.success) {
      notification({ variant: query.success ? 'success' : 'error', message: query.message });
    }
    else if (query.success) {
      notification({ variant: 'success', message: 'Proposition annulée' });
    }

    // update state
    if (query.success) {
      setProposals(prev => {
        const newProposals = { ...prev };
        delete newProposals[proposal_id];
        return newProposals;
      })
    }
  }

  // 
  // ─── HANDLE CLIENT CHECKOUT ───────────────────────────────────────
  //
  const handleCheckout = async () => {
    const obj = {
      email: user.email,
      mission_id: uuid,
      origin: mission.origin_city,
      destination: mission.destination_city,
    }

    const params = {
      endpoint: '/stripe/checkout',
      method: 'POST',
      data: obj
    }

    const query = await Api(params);
    if (query.success) window.location = query.data;
  }

  // 
  // ─── END MISSION : CAPTURE/TRANSFERS ───────────────────────────────────────
  //
  const endMission = async (obj) => {
    setCaptureLoading(true);
    let params;

    if (mission.external_payment === 1) {
      params = {
        data: {
          mission_id: uuid,
          fields: { status: 3 },
          send_mail: 'mission_complete_external_payment',
          send_mail_data: obj
        },
        endpoint: '/missions',
        method: 'PUT'
      }
    }
    else {
      params = {
        data: {
          mission_id: uuid,
          send_mail_data: obj
        },
        endpoint: '/stripe/capture',
        method: 'POST'
      }
    }

    const query = await Api(params);
    setCaptureLoading(false);

    if (query.success) setMission(prev => ({ ...prev, status: 3 }));
    const message = query.success ? 'Mission terminée' : query.message;
    notification({ variant: query.success ? 'success' : 'error', message });
  }

  // 
  // ─── DRIVER RENDER ───────────────────────────────────────
  //
  const UserRender = (props) => {
    const { dataKey } = props;
    const obj = Object.values(proposals)[0];
    let note;

    if (!obj) return
    if (obj[`${dataKey}_note`]) note = Number(obj[`${dataKey}_note`]).toFixed(1);

    return <S.Driver>
      <div className='header'>
        {obj[`${dataKey}_profile_picture`] && <img src={`data:image/jpeg;base64, ${obj[`${dataKey}_profile_picture`]}`} alt='Profil' />}
        <div>
          <span className='name'>{obj[`${dataKey}_firstname`]} {obj[`${dataKey}_name`]}</span>
          {((mission.status === 2 && (mission.session_id || mission.external_payment === 1)) || mission.status > 2) && <>
            {note && <span className='note'>
              {note}
              <Stars score={note} />
            </span>}
            <a href={`mailto:${obj[dataKey]}`}>{obj[dataKey]}</a>
            <a href={`tel:${obj[`${dataKey}_phone`]}`}>{obj[`${dataKey}_phone`]}</a>
          </>}
        </div>
      </div>
      <p>{obj[`${dataKey}_description`]}</p>
    </S.Driver>
  }

  // 
  // ─── PROPOSAL RENDER ───────────────────────────────────────
  //
  const ProposalRender = (props) => {
    const driverNote = Number(reviews[props.driver_user_id]).toFixed(1);
    const invitedDriverNote = Number(reviews[props.invited_driver_user_id]).toFixed(1);

    // la db renvoie la date avec deux heures en plus
    const dateLessTwoHours = new Date(props.date).setHours(new Date(props.date).getHours() - (isInReunion(mission.origin_cp) ? 4 : 2));

    return (
      <S.Team>
        {props.firstElem && <h3>Postulations</h3>}
        <div>
          <div className='names'>
            <div>
              <span>{props.driver_firstname} {props.driver_name}</span>
              <span className='type'>{window._DATA.types.driver_type[props.driver_type].label}</span>
              <Stars score={driverNote} />
            </div>
            {withVehicle && !props.solo && <div>
              <span>{props.invited_driver_firstname} {props.invited_driver_name}</span>
              <span className='type'>{window._DATA.types.driver_type[props.invited_driver_type].label}</span>
              <Stars score={invitedDriverNote} />
            </div>}
          </div>
          <div className='price'>
            <span>{props.external_payment === 1 ? 'Estimation (paiement sur place)' : 'Prix'}</span>
            <span>{(props.price + props.price_fee).toFixed(2)}€</span>
          </div>
          <div className='price'>
            <span>Date</span>
            <span>{formatDate({ display: 'date', time: props.date })} à {formatDate({ display: 'timer', time: dateLessTwoHours })}</span>
          </div>
          <div className='icons'>
            <Icon onClick={() => acceptTeamProposal(props.proposal_id)}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
              <span>Accepter</span>
            </Icon>
            <Icon onClick={() => deleteProposal({ proposal_id: props.proposal_id })}>
              <svg xmlns="http}://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
              <span>Refuser</span>
            </Icon>
          </div>
        </div>
      </S.Team>
    )
  }

  // 
  // ─── SHOW MODALS ───────────────────────────────────────
  //
  const showDeleteModal = () => {
    setDeleteModal({
      show: true,
      mission: mission,
      mission_id: mission.mission_id
    })
  }

  const showDisputeModal = () => {
    setDisputeModal({
      show: true,
      mission_id: mission.mission_id
    })
  }

  // 
  // ─── EDIT MISSION ───────────────────────────────────────
  //
  const handleEditMission = () => {
    navigate(`/trajets/modifier/${mission.mission_id}`);
  }

  // 
  // ─── DRIVER CANCEL POSTULATION WHEN STATUS 2 ───────────────────────────────────────
  //
  const cancelPostulation = async () => {
    const { proposal_id } = Object.values(proposals)[0];

    const fields = { status: 1, price: null, price_fee: null, session_id: null, external_payment: 0 };
    if (!mission.initial_date) {
      fields.date_type = 1;
      fields.date = null;
    }
    else fields.date = new Date(mission.initial_date).toISOString().slice(0, 16);

    const missionsParams = {
      data: { mission_id: uuid, fields, send_mail: 'driver_canceled_accepted_mission' },
      endpoint: '/missions',
      method: 'PUT'
    }

    const proposalsParams = {
      data: { proposal_id },
      endpoint: '/proposals',
      method: 'DELETE'
    }

    // pas de promise all car on se sert des proposals dans la query mission
    const missionQuery = await Api(missionsParams);
    if (missionQuery.success) {
      const proposalQuery = await Api(proposalsParams);
      if (proposalQuery.success) {
        notification({ variant: 'success', message: 'Vous avez annulé votre postulation à la mission' });
        navigate('/nouvelles-courses');
      }
    }
  }

  const showEndMissionModal = () => {
    setEndMssionModal({
      show: true,
      proposal: Object.values(proposals)[0],
    })
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //

  if (mission.status > 1 && user.active_role !== 1) {
    let proposalStatus = [];
    Object.entries(proposals || {}).map(([key, value]) => {
      proposalStatus.push(value.proposal_status)
    });
    if (!proposalStatus.includes(1)) return null;
  }

  if (Object.keys(mission).length === 0) return null;
  return <div>
    {deleteModal.show && <ModalMissionDelete modal={deleteModal} setModal={setDeleteModal} />}
    {disputeModal.show && <ModalDispute modal={disputeModal} setModal={setDisputeModal} />}
    {endMissionModal.show && <ModalEndMission endMission={endMission} modal={endMissionModal} setModal={setEndMssionModal} />}
    <S.Container className='page-container'>
      <S.Content>
        <S.Header>
          <h1>Détail du trajet</h1>
          {user.active_role === 2 && mission.status === 1 && Object.keys(proposals).length === 0 && <Icon onClick={handleEditMission}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" /></svg>
            <span>Modifier</span>
          </Icon>}
          {(mission.status === 1 || (mission.status === 2 && isMoreThanOneHour)) && isAdminOrCreator && !mission.session_id && <Icon onClick={showDeleteModal}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>
            <span>Supprimer</span>
          </Icon>}
        </S.Header>
        <Loading loading={loading}>
          <Summary data={mission} newTravel={false} />
          <S.OthersInfoContainer>
            {mission.status === 1 && <>
              {Boolean(user.stripe_charges_enabled) ? <S.Postulations>
                {hasTeamInvite && Object.values(proposals).filter(teamInviteCond).map((proposal, i) => (
                  <Fragment key={proposal.proposal_id}>
                    {i === 0 && <h3>Postulations de vos coéquipiers</h3>}
                    <div>
                      <span style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
                        <span >{proposal.driver === user.email ? proposal.invited_driver_firstname : proposal.driver_firstname}</span>
                        <span>{proposal.driver === user.email ? proposal.invited_driver_name : proposal.driver_name}</span>
                        <span>{proposal.driver === user.email ? proposal.invited_driver_phone : proposal.driver_phone}</span>
                        <span>{getTeammateEmail(proposal)}</span>
                      </span>
                      <Icon onClick={() => acceptProposal(proposal.proposal_id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                        <span>Accepter</span>
                      </Icon>
                      <Icon onClick={() => deleteProposal({ proposal_id: proposal.proposal_id, invitedDriverRefuse: true })}>
                        <svg xmlns="http}://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
                        <span>Refuser</span>
                      </Icon>
                    </div>
                  </Fragment>
                ))}
                {hasTeammatePostulations && Object.values(proposals).filter(teammatePostulationsCond).map((proposal, i) => (
                  <Fragment key={proposal.proposal_id}>
                    {i === 0 && <h3>Postulations de chauffeurs</h3>}
                    <div>
                      <span>{getTeammateEmail(proposal)}</span>
                      <Icon onClick={() => acceptProposal(proposal.proposal_id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" /></svg>
                        <span>Accepter</span>
                      </Icon>
                      <Icon onClick={() => deleteProposal({ proposal_id: proposal.proposal_id, driverRefuse: true })}>
                        <svg xmlns="http}://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
                        <span>Refuser</span>
                      </Icon>
                    </div>
                  </Fragment>
                ))}
                {Object.values(proposals).filter(e => (e.solo === 1 || (e.driver_approval === 1 && e.teammate_approval === 1)) && e.mission_id === mission.mission_id).map((proposal, i) => (
                  <S.Postulated key={proposal.proposal_id}>
                    {i === 0 && <h3>Vos postulations</h3>}
                    {proposal.invited_driver && <div>
                      <span style={{ marginBottom: 10 }}>Coéquipier</span>
                      <span>
                        <span style={{ marginRight: 8 }}>{proposal.driver === user.email ? proposal.invited_driver_firstname : proposal.driver_firstname}</span>
                        <span>{proposal.driver === user.email ? proposal.invited_driver_name : proposal.driver_name}</span>
                      </span>
                      <span>{proposal.driver === user.email ? proposal.invited_driver_phone : proposal.driver_phone}</span>
                      <span>{getTeammateEmail(proposal)}</span>
                    </div>}
                    <div>
                      <span>Date</span>
                      <span>{formatDate({ display: 'date', time: proposal.date })} à {formatDate({ display: 'timer', time: new Date(proposal.date).setHours(new Date(proposal.date).getHours() - (isInReunion(mission.origin_cp) ? typeof proposal.date == 'number' ? 2 : 4 : 2)) })}</span>
                    </div>
                    <div>
                      <span>Prix</span>
                      <span>{proposal.price?.toFixed(2)}€</span>
                    </div>
                    <Icon onClick={() => deleteProposal({ proposal_id: proposal.proposal_id, cancel: true })}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z" /></svg>
                      <span>Annuler</span>
                    </Icon>
                  </S.Postulated>
                ))}
              </S.Postulations>
                : user.active_role === 3 && <PostulationWarning />}
              {hasTeamPostulation && Object.values(proposals).map((proposal, i) => (
                <ProposalRender {...proposal} key={proposal.proposal_id} firstElem={i === 0} />
              ))}
            </>}
            {mission.status >= 2 && <>
              {Object.keys(proposals).length > 0 && (user.active_role !== 3 || (withVehicle && Object.values(proposals)[0].solo === 0 && user.active_role === 3)) && <S.Drivers>
                <h3>{withVehicle && Object.values(proposals)[0].solo === 0 ? 'Binôme' : 'Chauffeur'}</h3>
                {(user.active_role !== 3 || (Object.values(proposals)[0].driver !== user.email)) && <UserRender dataKey='driver' />}
                {Object.values(proposals)[0].invited_driver && (user.active_role !== 3 || (Object.values(proposals)[0].invited_driver !== user.email)) && <UserRender dataKey='invited_driver' />}
              </S.Drivers>}
              {user.active_role !== 2 && <S.Drivers>
                <h3>Informations du client</h3>
                <UserRender dataKey='client' />
              </S.Drivers>}
              {user.active_role === 2 && mission.status !== 3 && <S.PayContainer paid={mission.session_id}>
                {mission.session_id || mission.external_payment === 1 ? <S.EndButtons>
                  <LoadingButton onClick={showEndMissionModal} loading={captureLoading}>Arrivé à destination</LoadingButton>
                  <Button onClick={showDisputeModal} error={true}>Litige</Button>
                </S.EndButtons>
                  : <S.Pay onClick={!mission.session_id && handleCheckout}>
                    {mission.session_id ? 'Payée' : 'Payer'}
                  </S.Pay>}
              </S.PayContainer>}
              {user.active_role === 3 && mission.status !== 3 && <S.PayContainer paid={mission.session_id}>
                <S.Pay onClick={cancelPostulation}>
                  Annuler la mission
                </S.Pay>
              </S.PayContainer>}
            </>}
            {[1, 4].includes(user.active_role) && mission.status === 1 && <S.Drivers>
              <h3>Informations du client</h3>
              <S.Driver>
                <div className='header'>
                  <div>
                    <span className='name'>{mission.firstname} {mission.name}</span>
                    <a href={`mailto:${mission.email}`}>{mission.email}</a>
                    <a href={`tel:${mission.phone}`}>{mission.phone}</a>
                  </div>
                </div>
              </S.Driver>
            </S.Drivers>}
            {Boolean(user.active_role === 1 && allProposals.length) && <S.Proposals>
              <h3>Liste des propositions</h3>
              <S.ProposalsContainer>
                {allProposals.map((proposal) => {
                  return <S.Proposal>
                    <div className='header'>
                      <div>
                        <span className='name'>{proposal.driver_firstname} {proposal.driver_name}</span>
                        <a href={`mailto:${proposal.driver}`}>{proposal.driver}</a>
                        <a href={`tel:${proposal.driver_phone}`}>{proposal.driver_phone}</a>
                      </div>
                    </div>
                    {proposal.invited_driver_firstname && <div className='header'>
                      <div>
                        <span className='name'>{proposal.invited_driver_firstname} {proposal.invited_driver_name}</span>
                        <a href={`mailto:${proposal.invited_driver}`}>{proposal.invited_driver}</a>
                        <a href={`tel:${proposal.invited_driver_phone}`}>{proposal.invited_driver_phone}</a>
                      </div>
                    </div>}
                  </S.Proposal>
                })}
              </S.ProposalsContainer>
            </S.Proposals>}
          </S.OthersInfoContainer>
        </Loading>
      </S.Content>
    </S.Container>
  </div >
};

export default TravelDetail;

//
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  
  h1 {
    margin-bottom: 20px;
  }

  @media(min-width: 800px) {
    margin-top: 0;
  }

  .postulations-warning {
    margin-top: 25px;
  }
`

S.Content = styled.div`
  position: relative;
`

S.Postulations = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 10px;
  flex-direction: column;

  h3 {
    margin-bottom: 10px;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

    > span {
      margin-right: auto;
    }

    // contenant svg
    div {
      border-radius: 50%;
      background: ${({ theme }) => theme.primary};

      svg {
        fill: ${({ theme }) => theme.textInverse};
        width: 17px;
        padding: 8px 8.5px;
      }

      span {
        top: 37px;
      }
    }
  }
`

S.Postulated = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  &:not(:last-child){
    padding-bottom:10px;
    border-bottom: 1px solid #FFFFFF;
  }

  > div:not(:last-child) {
    display: flex;
    flex-direction: column;
    background: none;
    align-items: flex-start;
    margin-right: 15px;
    gap: 5px;
  }

  > div:last-child {
    margin-left: auto;

    > span {
      margin: 0 !important;
    }
  }

  h3 {
    width: 100%;
  }
`

S.Team = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 0;
  border-bottom: 2px solid ${({ theme }) => theme.navbarIcons};

  h3 {
    margin-bottom: 10px;
    margin-top: 20px;
  }

  // container
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;

    // svgs
    .icons {
      display: flex;
      margin-left: auto;
      gap: 10px;

      // contenant svg
      div {
        border-radius: 50%;
        background: ${({ theme }) => theme.primary};
        width: fit-content;

        svg {
          fill: ${({ theme }) => theme.textInverse};
          width: 16px;
          padding: 8px 8.5px;
        }

        span {
          top: 37px;
        }
      }
    }
  }

  .price {
    display: flex;
    flex-direction: column;
  }

  .names {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    // infos driver
    > div {
      display: flex;
      flex-direction: column;
      min-width: 94px;

      // stars
      > div {
        margin-top: -10px;
        height: 34px;
      }

      .type {
        font-size: 12px;
      }
    }
  }

  :last-of-type {
    border-bottom: none;
  }
`

S.Drivers = styled.div`
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  h3 {
    margin-bottom: 5px;
  }
`

S.Driver = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  :nth-of-type(2) {
    border-top: 2px solid ${({ theme }) => theme.navbarIcons};
    padding-top: 15px;
  }

  :nth-of-type(2) {
    margin-top: 5px;
  }

  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
  }
  .header {
    display: flex;
    gap: 20px;
    font-size: 15px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }

  .name {
    font-size: 16px;
    font-weight: 500;
  }

  .note {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 20px;
  }

  p {
    font-size: 14px;
    line-height: 1.4;
  }

  a {
    color: ${({ theme }) => theme.primary};
  }
`

S.PayContainer = styled.div`
  position: fixed;
  padding-bottom: 60px;
  padding-top: 15px;
  bottom: 50px;
  width: 100%;
  background: ${({ theme }) => theme.linearPay};
  left: 0;
  display: flex;
  justify-content: center;

  @media (min-width: 800px) {
    bottom: 0;
  }
`

S.EndButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  max-width: 600px;

  button {
    width: 45%;
  }  
`

S.Pay = styled(Button)`
  width: 90%;
  max-width: 300px;
`

S.Header = styled.div`
  display: flex;
  gap: 15px;
  justify-content: space-between;

  svg {
    fill: ${({ theme }) => theme.primary};
    width: 25px;
    height: 25px;
    transition: .3s;

    :hover {
      fill: ${({ theme }) => theme.primaryDark};
    }
  }

  span {
    top: 28px;
  }

  h1 {
    width: 100%;
  }
`

S.OthersInfoContainer = styled.div`
  @media (max-width: 800px) {
    padding: 20px;
  }
`

S.Proposals = styled.div`
  margin-top: 20px;

`
S.ProposalsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap:20px;
`

S.Proposal = styled.div`
  display: flex;
  /* flex-direction: column; */
  gap: 20px;

  img {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    object-fit: cover;
  }
  .header {
    display: flex;
    gap: 20px;
    font-size: 15px;

    > div {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
  }

  .name {
    font-size: 16px;
    font-weight: 500;
  }

  .note {
    display: flex;
    align-items: center;
    gap: 10px;
    height: 20px;
  }

  p {
    font-size: 14px;
    line-height: 1.4;
  }

  a {
    color: ${({ theme }) => theme.primary};
  }
`