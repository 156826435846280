import styled from "styled-components/macro";

export const Button = styled.button`
  border: none;
  cursor: pointer;
  height: 42px;
  background: ${({ theme, outline, error }) => outline ? theme.radioDesktop : error ? theme.error : theme.primary};
  padding: 10px 20px;
  font-family: ${({ theme }) => theme.primaryFont};
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme, outline }) => outline ? theme.text : theme.textInverse};
  border-radius: 5px;
  width: 100%;
`

export const RadioContainer = styled.div`
  display: flex;
  gap: 11px;
  width: 100%;
`

export const Radio = styled.label`
  color: ${({ theme }) => theme.text};
  font-weight: 600;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: calc(50% - 5.5px);
  height: 110px;
  border-radius: 7px;
  text-align: center;
  border: 4px solid;
  box-sizing: border-box;
  cursor: pointer;
  transition: .2s;
  background: ${({ theme, $cardColor, checked }) => checked ? theme.backgroundDarker : $cardColor ? theme.card : theme.radioDesktop};
  border-color: ${({ theme, checked, $cardColor }) => checked ? theme.primary : $cardColor ? theme.card : theme.radioDesktop};

  input {
    display: none;
  }

  svg {
    width: ${({ svgWidth }) => `${svgWidth || 50}px`};
    fill: ${({ theme }) => theme.text};
  }

  span {
    font-weight: 400;
    font-size: 14px;
  }

  &:hover {
    border-color: ${({ theme }) => theme.primary};
  }

  @media(min-width: 800px) {
    height: 130px;
    background: ${({ theme, checked }) => checked ? theme.background : theme.radioDesktop};
    border-color: ${({ theme, checked }) => checked ? theme.primary : theme.radioDesktop};

    svg {
      width: ${({ svgWidth }) => `${svgWidth || 60}px`} !important;
    }

    span {
      font-size: 16px;
    }
  }
`

export const Icon = styled.div`
    position: relative;
    display: flex;
    justify-content: center;

    svg {
        width: ${({ width }) => width || "15px"};
        height: ${({ height }) => height || "15px"};
        fill: ${({ theme }) => theme.primary};
        cursor: pointer;

        :hover ~ span {
            opacity: 1;
        }
    }
    span {
        color: white;
        display: none;
        transition: 0.2s;
        opacity: 0;
        position: absolute;
        white-space: nowrap;
        top: 20px;
        font-size: 12px;
        background: #000000e3;
        z-index: 100;
        padding: 2px 6px;
        border-radius: 5px;
        pointer-events: none;
    }

    @media (min-width: 800px) {
        span {
          display: block;
        }
    }
`;