import { useState } from 'react';
import styled from 'styled-components/macro';
import Formfields from './form/Formfields';
import { Button } from './StyledComponents';
import { prepareFormData } from '../contexts/Utils';
import Cookies from 'universal-cookie';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalPostulation = (props) => {
  const cookies = new Cookies();
  const user = cookies.get(process.env.REACT_APP_USER_COOKIE);
  const { setModal, modal, handlePostulate } = props;
  const [error, setError] = useState(false);

  const closeModal = () => {
    setModal({
      show: false
    })
  }

  const getCustomPrice = (e) => {
    e.preventDefault();
    let customPrice, fee;

    const data = prepareFormData({ formId: 'postulation-form' });
    if (modal.price && !data.fields.price) return setError('Veuillez indiquer un prix');
    else setError(false);

    if (modal.price) {
      customPrice = Number(data.fields.price).toFixed(2);
      fee = (customPrice * modal.sbs_margin / 100).toFixed(2);
    }

    const obj = {
      withTeammate: modal.withTeammate,
      priceParam: customPrice ? { amount: customPrice, fee } : undefined,
      date: data.fields.date,
      solo: modal.solo,
      external_payment: data.fields.external_payment || false
    }
    handlePostulate(obj);
    closeModal();
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <S.Content id="postulation-form">
      <p>Confirmer les informations</p>
      <S.Inputs>
        {modal.price !== undefined && <Formfields field={{ type: 'number', name: 'price', label: 'Prix (€)', default: modal.price.amount, width: '110px', error }} />}
        <Formfields field={{ type: 'datetime-local', name: 'date', label: 'Date', default: modal.date, width: '180px' }} />
        {user.driver_type === 2 && <Formfields field={{ component: 'checkbox', name: 'external_payment', label: 'Le client paiera sur place (ce prix sera une estimation)', width: 'calc(110px + 180px + 15px)' }} />}
      </S.Inputs>
      <S.Buttons>
        <Button type="button" onClick={closeModal} outline={true}>Annuler</Button>
        <Button onClick={getCustomPrice}>Valider</Button>
      </S.Buttons>
    </S.Content>
    <S.Background onClick={closeModal}></S.Background>
  </S.Container>
};

export default ModalPostulation;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  position: relative;
`

S.Content = styled.form`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${({ theme }) => theme.card};
  color: ${({ theme }) => theme.text};
  border-radius: 5px;
  box-shadow: 0px 0px 10px 6px rgb(0 0 0 / 23%);
  padding: 20px;
  max-width: 400px;
  width: calc(100% - 40px);
  box-sizing: border-box;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-bottom: 25px;
    font-size: 20px;
    font-weight: 600;
  }

  input:not([type="submit"]) {
    border: 1.5px solid #8a8a8a !important;
    border-radius: 5px;
  }
`

S.Inputs = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  color-scheme: dark;

  span {
    white-space: nowrap;
  }

  input[name="external_payment"] {
    background: none;
  }
`

S.Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  width: 100%;

  button {
    width: 160px;
  }
`

S.Background = styled.div`
  inset: 0;
  position: fixed;
  background: #000000a3;
`