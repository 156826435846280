import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { getValue } from '../../contexts/Utils';
import Formfields from './Formfields';
import { Button } from '../StyledComponents';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Vehicles = ({ data, errors, horsepowerData }) => {
  const vehicleInit = useMemo(() => ({
    brand: '',
    type: '',
    horsepower: '',
  }), []);
  const keys = useMemo(() => ['brand', 'car_type', 'horsepower'], []);
  const [vehicles, setVehicles] = useState([vehicleInit]);

  // generate default values
  useEffect(() => {
    const vehiclesData = Object.entries(data).filter(([key, val]) => keys.some(e => key.includes(e)));
    const length = vehiclesData.length / 3;

    let array = [];
    for (let i = 0; i < length; i++) {
      let obj = {};
      keys.forEach(key => obj[`${key}_${i}`] = data[`${key}_${i}`]);
      array.push(obj);
    }

    setVehicles(length ? array : [vehicleInit]);
  }, [data, keys, vehicleInit])

  // add a vehicle to the list
  const addVehicle = (e) => {
    e.preventDefault();
    setVehicles(prev => [...prev, vehicles])
  }

  const formFields = [
    { component: "text", name: "brand", label: "Marque du véhicule" },
    { component: "text", name: "car_type", label: "Type du véhicule" },
    { component: "select", name: "horsepower", label: "CV", data: horsepowerData },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    {vehicles.map((vehicle, i) => <S.Vehicle key={i}>
      <h3>Véhicule {i + 1}</h3>
      {formFields.map((field, n) => <Formfields key={`${i}_${n}`} field={{ ...field, name: `${field.name}_${i}`, default: data[`${field.name}_${i}`], error: getValue(errors, [`${field.name}_${i}`, 'message']) }} />)}
    </S.Vehicle>)}
    <S.Button onClick={addVehicle}>Ajouter</S.Button>
  </S.Container>
};

export default Vehicles;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}
S.Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  h3 {
    font-size: 16px;
  }
`

S.Vehicle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`

S.Button = styled(Button)``