import { useState, useEffect, useContext, useMemo } from "react";
import { Outlet, useLocation } from 'react-router-dom';
import Cookies from "universal-cookie";
import { Context } from "../../App";
import Api from "../../contexts/Api";
import { Logout } from "../../contexts/Utils";

const AuthChecker = () => {
  let location = useLocation();
  const cookies = useMemo(() => new Cookies(), []);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { setTheme } = useContext(Context);

  // 
  // ─── CHECK IF USER IS CORRECTLY AUTHENTICATED ───────────────────────────────────────
  //
  useEffect(() => {
    const checkUserAuthentication = async () => {
      const params = { endpoint: '/auth/authenticate' }
      const isAuthenticated = await Api(params);

      if (!isAuthenticated.success) {
        Logout();
      } else {
        if (isAuthenticated.data.disabled) {
          Logout();
        } else {
          // 
          // ─── RESET COOKIE WITH CURRENT USER INFOS ───────────────────────────────────────
          //
          cookies.remove(process.env.REACT_APP_USER_COOKIE);
          cookies.set(process.env.REACT_APP_USER_COOKIE, isAuthenticated.data, {
            expires: new Date(Date.now() + 604800000), // time until expiration
            secure: false, // true if using https
            path: '/' // if not set, will gen cookie for different pages
          })

          // 
          // ─── UPDATE THEME BY ROLE ───────────────────────────────────────
          //
          const role = isAuthenticated.data.active_role
          setTheme([1, 3, 4].includes(role) ? 'dark' : 'light')



          setIsAuthenticated(true);
        }
      }
    }
    checkUserAuthentication();

  }, [location, cookies, setTheme]);

  // 
  // ─── DISPLAY APP PAGES IF USER IS AUTHENTICATED ───────────────────────────────────────
  //
  if (isAuthenticated) {
    return <Outlet />
  }
}

export default AuthChecker;